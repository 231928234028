/*
const pageView = (action, prevState, nextState) => ({
        hitType: 'pageview',
        page: action.payload,
});
*/

const event = (action, prevState, nextState) => ({
        event_category: 'cardcruncher',
        event: action.eventType,
        event_label: action.eventLabel,
        event_value: action.eventValue,
})

const eventsMap = {
    'LOG_EVENT': event,
    /*
     * Don't log location change events (we track those through
     * gtm.historyChange). This is useful for debugging though so don't delete
     * this line.
     */
    //'@@router/LOCATION_CHANGE': pageView,
};

export { eventsMap }