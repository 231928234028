import React from "react"
import YouTube from "react-youtube"
import Scroll from "react-scroll"
import { Link } from "react-router-dom"
import { Button, Typography, LazyImage, ResponsiveEmbed } from "../../atoms"
import { Modal } from "../../organisms"
import { Container } from "../../templates"

// import CardsImage from "../../../assets/images/3d-cards-group@2x.png";
import VideoSectionImage from "../../../assets/images/video-section-bg.png"
import MacImage from "../../../assets/images/mac@2x.png"
import AnalyzeSpendingImage from "../../../assets/images/analyze@3x.svg"
import MatchCardsImage from "../../../assets/images/match@3x.svg"
import BonusesImage from "../../../assets/images/bonuses@3x.svg"
import NetFeesImage from "../../../assets/images/net-fees.svg"
import PerfectCardImage from "../../../assets/images/perfect-card@2x.png"
import AllCardsImage from "../../../assets/images/all-cards@2x.png"
import Img1Mobile from "../../../assets/images/3d-cards-group--mobile.png"
import Img1Mobile1 from "../../../assets/images/3d-cards-group--mobile@1.5x.png"
import Img1Mobile2 from "../../../assets/images/3d-cards-group--mobile@2x.png"
import Img1Mobile3 from "../../../assets/images/3d-cards-group--mobile@3x.png"
import Img1Desktop from "../../../assets/images/3d-cards-group--desktop.png"
import Img1Desktop1 from "../../../assets/images/3d-cards-group--desktop@1.5x.png"
import Img1Desktop2 from "../../../assets/images/3d-cards-group--desktop@2x.png"
import Img1Desktop3 from "../../../assets/images/3d-cards-group--desktop@3x.png"

const LandingSection = props => {
    return <section className="o-landing-section">
        <Container>
            <article className="o-landing-section__content is-1">
                <Typography component="h1" variant="h1">{props.title}</Typography>
                <Typography component="h2" variant="h1">Easy.</Typography>
            </article>
            <div className="o-landing-section__object">
                {/* <LazyImage srcPreload={CardsImage} alt="" /> */}
                <picture>
                    <source
                        media="(min-width: 768px)"
                        srcSet={`${Img1Desktop},
                                 ${Img1Desktop1} 1.5x,
                                 ${Img1Desktop2} 2x,
                                 ${Img1Desktop3} 3x`
                        }
                    />
                    <source
                        media="(min-width: 1280px)"
                        srcSet={`${Img1Desktop},
                                 ${Img1Desktop1} 1.5x,
                                 ${Img1Desktop2} 2x,
                                 ${Img1Desktop3} 3x`
                        }
                    />
                    <img
                        src={Img1Mobile}
                        srcSet={`${Img1Mobile1} 1.5x,
                                 ${Img1Mobile2} 2x,
                                 ${Img1Mobile3} 3x`
                        }
                        alt=""
                    />
                </picture>
            </div>
            <article className="o-landing-section__content is-2">
                <p>{props.text}</p>
                <span>Start here. It’s free!</span>
                {props.userHasConnectedPlaid ? (
                    <Link className="a-button a-button--primary" to="/top-picks">View my Top Picks</Link>
                ) : (
                    <Button variant="primary" onClick={props.onClick}>Crunch your spending history</Button>
                )}
            </article>
        </Container>
    </section>
}

const FeatureSection = props => (
    <section className="o-feature-section">
        <article className="m-feature --half is-1">
            <div className="m-feature__content">
                <span className="m-feature__icon">1</span>
                <h2>{props[1].title}</h2>
                <p>{props[1].text}</p>
            </div>
        </article>
        <article className="m-feature --half is-2">
            <div className="m-feature__content">
                <span className="m-feature__icon">2</span>
                <h2>{props[2].title}</h2>
                <p>{props[2].text}</p>
            </div>
        </article>
        <article className="m-feature is-3">
            <div className="m-feature__content">
                <span className="m-feature__icon">3</span>
                <h2>{props[3].title}</h2>
                <p>{props[3].text}</p>
            </div>
            <LazyImage className="m-feature__image" srcPreload={MacImage} type="background" alt="" />
        </article>
    </section>
)

const VideoSection = props => (
    <section className="o-video-section">
        <LazyImage className="o-video-section__bg-image" srcPreload={VideoSectionImage} type="background" />
        <div className="o-video-section__content">
            <div className="play-button" onClick={props.onClick} />
            <h2>{props.title}</h2>
            <p>{props.text}</p>
        </div>
    </section>
)

const CoreSection = props => (
    <section className="o-core-section">
        <Container>
            <div className="o-core-section__content">
                <h2>Underneath the engine</h2>
                <p>
                    Every time you purchase with a debit or credit card, your bank or credit card company categorizes transactions into groupings like gas, groceries, restaurants, etc. Credit card issuers use these categories to issue rewards.
                </p>
                <p>
                    CardCruncher compares your spending history to over 150 credit cards, factors in bonus amounts, annual spending credits, and fees to find you the best credit card.
                </p>
            </div>
            <div className="o-core-section__object">
                <div className="m-core is-1">
                    <div className="m-core__icon">
                        1
                        <LazyImage className="m-core__image" srcPreload={AnalyzeSpendingImage} alt="" />
                    </div>
                    <p className="m-core__name">Analyze Spending</p>
                </div>
                <div className="m-core is-2">
                    <div className="m-core__icon">
                        2
                        <LazyImage className="m-core__image" srcPreload={MatchCardsImage} alt="" />
                    </div>
                    <p className="m-core__name">Match to credit cards</p>
                </div>
                <div className="m-core is-3">
                    <div className="m-core__icon">
                        3
                        <LazyImage className="m-core__image" srcPreload={BonusesImage} alt="" />
                    </div>
                    <p className="m-core__name">Calculate bonuses and credits</p>
                </div>
                <div className="m-core is-4">
                    <div className="m-core__icon">
                        4
                        <LazyImage className="m-core__image" srcPreload={NetFeesImage} alt="" />
                    </div>
                    <p className="m-core__name">Calculate net fees</p>
                </div>
                <div className="m-core is-5">
                    <div className="m-core__icon">
                        5
                        <LazyImage className="m-core__image" srcPreload={PerfectCardImage} alt="" />
                    </div>
                    <p className="m-core__name">Perfect credit card</p>
                </div>
            </div>
        </Container>
    </section>
)

const CardsSection = props => (
    <section className="o-cards-section">
        <Container>
            <article className="o-cards-section__content">
                <Typography component="h2">{props.title}</Typography>
                <Typography component="p">{props.text}</Typography>
                <Button
                    variant="invert-primary"
                    className="--uppercase --600"
                    onClick={props.onClick}
                >
                    Explore all credit cards
                </Button>
            </article>
            <div className="o-cards-section__object">
                <LazyImage srcPreload={AllCardsImage} alt="" />
            </div>
        </Container>
    </section>
)

export function Home(props) {
    const Element = Scroll.Element

    return (
        <>
            <LandingSection
                {...props.data.landing}
                userHasConnectedPlaid={props.user_has_connected_plaid}
                onClick={props.onCrunchHistoryClick}
            />
            <Element name="how-it-works" id="how-it-works">
                <FeatureSection {...props.data.feature} />
            </Element>
            <VideoSection {...props.data.video} onClick={props.onVideoClick} />
            <CoreSection />
            <CardsSection {...props.data.cards} onClick={props.onCreditCardsClick} />
            <Modal
                className="o-video-modal"
                isOpen={props.isModalVisible}
                onRequestClose={props.onModalCloseClick}
            >
                <Modal.Header>
                    <Typography component="h3">What is CardCruncher?</Typography>
                </Modal.Header>
                <ResponsiveEmbed>
                    <YouTube
                        videoId="O4sOxjP2RUU"
                        opts={{
                            playerVars: {
                                autoplay: 1,
                                controls: 0,
                                disablekb: 1,
                                modestbranding: 1,
                                rel: 0,
                            }
                        }}
                        className=""
                    />
                </ResponsiveEmbed>
            </Modal>
        </>
    )
}
