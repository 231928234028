import actions from "../actions"

const initialState = {
    isFetching: false,
    payload: null,
}

export function envDataReducer(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_ENV_DATA:
            return {
                ...state,
                isFetching: true,
            }
        case actions.RECEIVE_ENV_DATA:
            return {
                isFetching: false,
                payload: {...action.payload}
            }
        default:
            return state
    }
}
