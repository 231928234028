export async function getRequest(url, loginToken) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (loginToken) {
    headers['Authorization'] = 'Token ' + loginToken;
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: headers
  });

  if (response.status < 400) {
    const responseJson = await response.json();
    return responseJson;
  }

  throw await response.json();
}

export async function postRequest(url, body, loginToken) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (loginToken) {
    headers['Authorization'] = 'Token ' + loginToken;
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  });

  if (response.status < 400) {
    const responseJson = await response.json();
    return responseJson;
  }

  throw await response.json();
}
