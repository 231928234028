let url = document.location.protocol + "//" + document.location.host + "/api/"
if (process.env.NODE_ENV === "development") {
    url = "http://127.0.0.1:8000/"
}

const loginWithFacebookSuccess = login_token => ({
    type: "LOGIN_WITH_FACEBOOK_SUCCESS",
    login_token,
})

export const loginWithFacebook = access_token => {
    return (dispatch, getState) => {
        const { login_token } = getState().root;

        fetch(`${url}api/login/facebook?access_token=${access_token}`, {
            method: 'GET',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + login_token,
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            const login_token = json.auth_token;
            dispatch(loginWithFacebookSuccess(login_token));
        })
        .catch(error => { console.error(error) })
    }
}

const loginWithGoogleSuccess = login_token => ({
    type: "LOGIN_WITH_GOOGLE_SUCCESS",
    login_token,
})

export const loginWithGoogle = access_token => {
    return (dispatch, getState) => {
        const { login_token } = getState().root

        fetch(`${url}api/login/google-oauth2?access_token=${access_token}`, {
            method: 'GET',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + login_token,
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            const login_token = json.auth_token;
            dispatch(loginWithGoogleSuccess(login_token));
        })
        .catch(error => { console.error(error) })
    }
}
