import React from "react"
// import PropTypes from "prop-types"
import cx from "classnames"

import "./bubble.scss"

function Bubble(props) {
    const classes = cx("a-bubble")

    return (
        <span className={classes}>{props.children}</span>
    )
}

export default Bubble

// Bubble.defaultProps = {}

// Bubble.propTypes = {}
