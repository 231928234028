import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./radiobox.scss";

function Radiobox(props) {
    const classes = cx("a-radiobox");

    return (
        <label className={classes}>
            <input
                name={props.value}
                type="radio"
                checked={props.checked}
                onChange={event => props.onChange(event, {
                    name: props.name,
                    value: props.value
                })}
            />
            <span className="a-radiobox__control" />
            <span className="a-radiobox__label">{props.name}</span>
        </label>
    )
}

export default Radiobox;

Radiobox.defaultProps = {
    checked: false,
    onChange: () => {}
}

Radiobox.propTypes = {
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}
