const initialState = {
  is_plaid_open: false,
  crunch_data: null,
  crunch_prefetch_fpr_crunch_data: null,
  crunch_prefetch_sfy_crunch_data: null,
  crunch_prefetch_fpr_and_sfy_crunch_data: null,
  cookies: {
    utm_campaign: '',
    utm_medium: '',
    utm_source: '',
    var2: ''
  },
  fake_prefetch_fpr_crunch_data: null,
  fake_prefetch_sfy_crunch_data: null,
  fake_prefetch_fpr_and_sfy_crunch_data: null,
  isCrunchFPRFetching: false,
  isCrunchSFYFetching: false,
  isCrunchFPRandSFYFetching: false,
  isFPRFetching: false,
  isSFYFetching: false,
  isFPRandSFYFetching: false,
  login_token: null,
  crunch_in_progress: false,
  crunch_error: false,
  highlighted_menu_item: null,
  loaded: false
};

// console.log("initialState", initialState)

export default function(state = initialState, action) {
  switch (action.type) {
    case 'PLAID_LOADED':
      return {
        ...state,
        loaded: true
      };
    case 'OPEN_PLAID_MODAL':
      return { ...state, show_plaid_pre_modal: true };
    case 'CLOSE_PLAID_MODAL':
      return { ...state, show_plaid_pre_modal: false };
    case 'STORE_MANUAL_CRUNCH':
      return { ...state, fake_crunch_profile: action.profile, crunch_data: null };
    case 'STORE_COOKIE':
      const cookies = state.cookies;

      if (action.cookie.name === 'utm_campaign') {
        cookies.utm_campaign = action.cookie.value;
      }

      if (action.cookie.name === 'utm_medium') {
        cookies.utm_medium = action.cookie.value;
      }

      if (action.cookie.name === 'utm_source') {
        cookies.utm_source = action.cookie.value;
      }

      if (action.cookie.name === 'var2') {
        cookies.var2 = action.cookie.value;
      }

      return {
        ...state,
        cookies: cookies
      };
    case 'OPEN_PLAID':
      return {
        ...state,
        show_plaid_pre_modal: false,
        is_plaid_open: true
      };
    case 'CLOSE_PLAID':
      return { ...state, is_plaid_open: false };
    case 'PLAID_EXIT':
      return { ...state, is_plaid_open: false };
    case 'CRUNCH_STARTED':
      return { ...state, crunch_in_progress: true, crunch_data: null, crunch_error: false };
    case 'CRUNCH_NOT_READY':
      return { ...state, crunch_attempts: state.crunch_attempts + 1 };
    case 'CRUNCH_SUCCESS':
      return {
        ...state,
        crunch_attempts: 0,
        crunch_in_progress: false,
        crunch_data: action.crunch_data
      };
    case 'PREFETCH_FPR_STARTED':
      return {
        ...state,
        isCrunchFPRFetching: true
      };
    case 'PREFETCH_FPR_SUCCESS':
      return {
        ...state,
        crunch_prefetch_fpr_crunch_data: action.crunch_data,
        // user_has_connected_plaid: false,
        isCrunchFPRFetching: false
      };
    case 'PREFETCH_SFY_STARTED':
      return {
        ...state,
        isCrunchSFYFetching: true
      };
    case 'PREFETCH_SFY_SUCCESS':
      return {
        ...state,
        crunch_prefetch_sfy_crunch_data: action.crunch_data,
        // user_has_connected_plaid: false,
        isCrunchSFYFetching: false
      };
    case 'PREFETCH_FPR_AND_SFY_STARTED':
      return {
        ...state,
        isCrunchFPRandSFYFetching: true
      };
    case 'PREFETCH_FPR_AND_SFY_SUCCESS':
      return {
        ...state,
        crunch_prefetch_fpr_and_sfy_crunch_data: action.crunch_data,
        // user_has_connected_plaid: false,
        isCrunchFPRandSFYFetching: false
      };
    case 'CRUNCH_FAILURE':
      return {
        ...state,
        crunch_attempts: 0,
        crunch_in_progress: false,
        crunch_error: true,
        isCrunchFPRFetching: false,
        isCrunchSFYFetching: false,
        isCrunchFPRandSFYFetching: false
      };
    case 'FAKE_CRUNCH_SUCCESS':
      return {
        ...state,
        crunch_attempts: 0,
        crunch_in_progress: false,
        crunch_data: action.crunch_data,
        login_token: action.auth_token,
        user_has_connected_plaid: false
      };
    case 'FAKE_PREFETCH_FPR_STARTED':
      return {
        ...state,
        isFPRFetching: true
        // crunch_data: null,
        // crunch_error: false,
      };
    case 'FAKE_PREFETCH_FPR_SUCCESS':
      return {
        ...state,
        fake_prefetch_fpr_crunch_data: action.crunch_data,
        login_token: action.auth_token,
        user_has_connected_plaid: false,
        isFPRFetching: false
      };
    case 'FAKE_PREFETCH_SFY_STARTED':
      return {
        ...state,
        isSFYFetching: true
      };
    case 'FAKE_PREFETCH_SFY_SUCCESS':
      return {
        ...state,
        fake_prefetch_sfy_crunch_data: action.crunch_data,
        login_token: action.auth_token,
        user_has_connected_plaid: false,
        isSFYFetching: false
      };
    case 'FAKE_PREFETCH_FPR_AND_SFY_STARTED':
      return {
        ...state,
        isFPRandSFYFetching: true
        // crunch_data: null,
        // crunch_error: false,
      };
    case 'FAKE_PREFETCH_FPR_AND_SFY_SUCCESS':
      return {
        ...state,
        fake_prefetch_fpr_and_sfy_crunch_data: action.crunch_data,
        login_token: action.auth_token,
        user_has_connected_plaid: false,
        isFPRandSFYFetching: false
      };
    case 'FAKE_CRUNCH_FAILURE':
      return {
        ...state,
        crunch_attempts: 0,
        crunch_in_progress: false,
        crunch_error: true,
        isFPRFetching: false,
        isSFYFetching: false,
        isFPRandSFYFetching: false
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        login_token: null,
        user_has_connected_social: false,
        user_has_connected_plaid: false,
        crunch_data: null
      };
    case 'EXCHANGE_PLAID_TOKEN_SUCCESS':
      return {
        ...state,
        login_token: action.login_token,
        user_has_connected_plaid: true
      };
    case 'LOGIN_WITH_FACEBOOK_SUCCESS':
      return {
        ...state,
        login_token: action.login_token,
        user_has_connected_social: true
      };
    case 'LOGIN_WITH_GOOGLE_SUCCESS':
      return {
        ...state,
        login_token: action.login_token,
        user_has_connected_social: true
      };
    case 'SUBMIT_EMAIL_FORM_SUCCESS':
      return {
        ...state,
        user_has_connected_social: true
      };
    default:
      return state;
  }
}
