import React from "react"
// import PropTypes from "prop-types"
import cx from "classnames"

import "./table.scss"

const TableHead = props => <thead>{props.children}</thead>   

const TableBody = props => <tbody>{props.children}</tbody>

const TableFoot = props => <tfoot>{props.children}</tfoot>

const TableTr = props => <tr>{props.children}</tr>

const TableTh = props => <th>{props.children}</th>

const TableTd = props => <td>{props.children}</td>

export default function Table(props) {
    const classes = cx(
        "o-table",
        props.className,
    )

    return <table className={classes}>{props.children}</table>
}

Table.Head = TableHead
Table.Body = TableBody
Table.Foot = TableFoot
Table.Tr = TableTr
Table.Th = TableTh
Table.Td = TableTd