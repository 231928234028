import actions from './';
import { sendApplyForApi } from '../../api';

export const sendApplyFor = (cardId, loginToken) => dispatch => {
    dispatch({ type: actions.SEND_APPLY_FOR })

    return sendApplyForApi(cardId, loginToken)
        .then(payload => {
            console.log('payload', payload);
        })
        .catch(error => { throw(error) })
}
