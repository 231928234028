// import 'react-app-polyfill/stable'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import logger from '@redux-beacon/logger';
import * as Sentry from '@sentry/browser';
import { loadState, saveState } from './utilities';
import { eventsMap } from './utilities/events';
import throttle from 'lodash/throttle';
import { MainTemplate, ScrollToTop } from './components/templates';
import { router } from './components/pages';
import rootReducer from './redux/reducers/__deprecated-reducers';
import { cardDetailsReducer } from './redux/reducers/card-details';
import { cardsListReducer } from './redux/reducers/cards-list';
import { pageContentReducer } from './redux/reducers/page-content';
import { envDataReducer } from './redux/reducers/env';
import { plaidReducer } from './redux/reducers/plaid';
import { isSnap } from './config/api-services-config';
import { getCardsList } from './redux/actions/cards-list';

import 'normalize.css/normalize.css';
import './assets/stylesheets/app.scss';

// if (process.env.NODE_ENV !== "development" && navigator.userAgent !== 'ReactSnap') {}
Sentry.init({ dsn: 'https://463e7c7a7ab64fdeafa09bde52c09a7f@sentry.io/1799999' });

const history = createBrowserHistory();
const reduxRouter = routerMiddleware(history);

const snapedState = window.__PRELOADED_STATE__ || {};

delete window.__PRELOADED_STATE__;

const persistedState = loadState() || {};
const initialState = {
  root: Object.assign(
    {
      loaded: false,
      login_token: null,
      user_has_connected_social: false,
      user_has_connected_plaid: false,
      is_plaid_open: false,
      plaid_public_token: null,
      cookies: {
        utm_campaign: '',
        utm_medium: '',
        utm_source: '',
        var2: ''
      },
      crunch_in_progress: false,
      crunch_attempts: 0,
      crunch_data: null,
      crunch_prefetch_fpr_crunch_data: null,
      crunch_prefetch_sfy_crunch_data: null,
      crunch_prefetch_fpr_and_sfy_crunch_data: null,
      isCrunchFPRFetching: false,
      isCrunchSFYFetching: false,
      isCrunchFPRandSFYFetching: false,
      fake_prefetch_fpr_crunch_data: null,
      fake_prefetch_sfy_crunch_data: null,
      fake_prefetch_fpr_and_sfy_crunch_data: null,
      isFPRFetching: false,
      isSFYFetching: false,
      isFPRandSFYFetching: false,
      crunch_error: false,
      show_plaid_pre_modal: false,
      show_manual_crunch_modal: false,
      fake_crunch_profile: null
    },
    persistedState
  ),
  ...snapedState,
  router: null
};

let beaconArgs = { logger };
let composeEnhancers = compose;
if (process.env.NODE_ENV === 'production') {
  beaconArgs = undefined;
}
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const gtm = GoogleTagManager();
const reduxBeaconMiddleware = createMiddleware(eventsMap, gtm, beaconArgs);

const store = createStore(
  combineReducers({
    cardsList: cardsListReducer,
    cardDetails: cardDetailsReducer,
    envData: envDataReducer,
    pageContent: pageContentReducer,
    root: rootReducer,
    router: routerReducer,
    plaid: plaidReducer
  }),
  initialState,
  composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(reduxRouter),
    applyMiddleware(reduxBeaconMiddleware)
  )
);

if (isSnap) store.dispatch(getCardsList());
store.subscribe(throttle(() => saveState(store.getState()), 500));

const getPreloadedState = () => {
  const storeState = store.getState();
  const pathName = window.location.pathname;
  const isCreditCardsRoute = pathName.indexOf('credit-cards') > 0 && pathName.length < 15;
  const isCardDetailsRoute = pathName.indexOf('credit-cards') > 0 && pathName.length > 15;
  const isHomeRoute = pathName === '/';
  const isPrivacyRoute = pathName === '/privacy';
  const isTermsRoute = pathName === '/terms';

  if (isCreditCardsRoute) {
    return {
      cardsList: storeState.cardsList,
      envData: storeState.envData
    };
  }

  if (isCardDetailsRoute) {
    return {
      cardDetails: storeState.cardDetails,
      envData: storeState.envData
    };
  }

  if (isHomeRoute) {
    return {
      envData: storeState.envData
    };
  }

  if (isPrivacyRoute) {
    return {
      pageContent: storeState.pageContent,
      envData: storeState.envData
    };
  }

  if (isTermsRoute) {
    return {
      pageContent: storeState.pageContent,
      envData: storeState.envData
    };
  }

  return {};
};

window.snapSaveState = () => ({
  __PRELOADED_STATE__: getPreloadedState()
});

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <MainTemplate>
            <Switch>{router}</Switch>
          </MainTemplate>
        </ScrollToTop>
      </Router>
    </Provider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <MainTemplate>
            <Switch>{router}</Switch>
          </MainTemplate>
        </ScrollToTop>
      </Router>
    </Provider>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
