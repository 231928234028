import React from 'react';

const createScript = (resolve, reject) => {
    const script = document.createElement('script');
    script.id = 'onesignal-script';
    script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
    script.type = 'text/javascript';
    script.async = true;
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', (e) => reject(e));

    return script;
}

const injectScript = () =>
    new Promise((resolve, reject) => {
        const scriptTag = document.getElementById("onesignal-script");
        if (!scriptTag) {
            const script = createScript(resolve, reject);
            document.head.appendChild(script);
        }
    })

const OneSignalScript = () => {
    React.useEffect(() => {
        injectScript().then(() => {
            const script = document.createElement('script');
            script.id = 'onesignal-init-script';
            script.type = 'text/javascript';
            document.head.appendChild(script);
            script.innerText = `var OneSignal = window.OneSignal || [];OneSignal.push(function() {OneSignal.init({appId: '${process.env.REACT_APP_ONE_SIGNAL_KEY}',autoResubscribe: true,notifyButton: {enable: false,},});navigator.serviceWorker.register(document.location.origin + '/OneSignalSDKWorker.js?appId=${process.env.REACT_APP_ONE_SIGNAL_KEY}');setTimeout(OneSignal.showNativePrompt, 2000);});`;
        })
    }, [])

    return <></>
}

export default OneSignalScript;
