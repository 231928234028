import React from 'react';
import { connect } from 'react-redux';
import { EmailCollection } from './email-collection';
import { logEvent } from '../../../redux/actions/__deprecated-actions';
import { submitEmailForm } from '../../../redux/actions/__deprecated-email-form';
import { loginWithFacebook, loginWithGoogle } from '../../../redux/actions/__deprecated-login';

import './email-collection.scss';

function EmailCollectionContainer(props) {
  const isModalClosed = sessionStorage.getItem('__cc__emailCollectionClosed');
  const [email, setEmail] = React.useState('');
  const [isModalOpenState, setModalOpen] = React.useState(true);

  const handleEmailChange = event => {
    const value = event.target.value;

    setEmail(value);
  };

  const handleModalClose = () => {
    if (isModalClosed === null) {
      sessionStorage.setItem('__cc__emailCollectionClosed', true);
    }

    setModalOpen(false);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    props.logEvent('click', 'Email Collection', `Submit email - ${email}`);
    props.submitEmailForm(email);
  };

  const handleLoginWithFacebook = response => {
    props.loginWithFacebook(response.accessToken);
    props.logEvent('facebook-login', 'facebook-login');
    // setModalOpen(false);
  };

  const handleLoginWithGoogle = response => {
    props.loginWithGoogle(response.accessToken);
    props.logEvent('google-login', 'google-login');
    // setModalOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (isModalOpenState && props.root.user_has_connected_plaid) {
      setModalOpen(false);
    }

    if (isModalOpenState && props.root.user_has_connected_social) {
      setModalOpen(false);
    }

    if (Boolean(isModalClosed) && isModalOpenState) {
      setModalOpen(false);
    }
  });

  return (
    <EmailCollection
      email={email}
      isModalOpen={isModalOpenState}
      onChange={handleEmailChange}
      onSubmit={handleFormSubmit}
      onModalClose={handleModalClose}
      onLoginWithFacebook={handleLoginWithFacebook}
      onLoginWithGoogle={handleLoginWithGoogle}
    />
  );
}

const mapStateToProps = ({ root }) => ({ root });

const mapDispatchToProps = dispatch => ({
  submitEmailForm: email => dispatch(submitEmailForm(email)),
  logEvent: (eventType, eventLabel, eventValue) =>
    dispatch(logEvent(eventType, eventLabel, eventValue)),
  loginWithFacebook: access_token => dispatch(loginWithFacebook(access_token)),
  loginWithGoogle: access_token => dispatch(loginWithGoogle(access_token))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailCollectionContainer);
