import React from 'react'

const createScript = (resolve, reject) => {
    const script = document.createElement('script');
    script.id = 'gto-script';
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-108202209-1';
    script.type = 'text/javascript';
    script.async = true;
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', (e) => reject(e));

    return script;
}

const injectScript = () =>
    new Promise((resolve, reject) => {
        const scriptTag = document.getElementById('gto-script');
        if (!scriptTag) {
            const script = createScript(resolve, reject);
            document.head.appendChild(script);
        }
    })

const GtoScript = () => {
    React.useEffect(() => {
        injectScript().then(() => {
            const script = document.createElement('script');
            script.id = 'gtm-init-script';
            script.type = 'text/javascript';
            document.head.appendChild(script);
            script.innerText = `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','UA-108202209-1', {'optimize_id':'GTM-TDM8G78'});`;
        })
    }, [])

    return <></>
}

export default GtoScript;
