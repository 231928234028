import actions from "../actions"

export function pageContentReducer(state = {
    isFetching: false,
    payload: null,
}, action) {
    switch (action.type) {
        case actions.REQUEST_PAGE_CONTENT:
            return {
                ...state,
                isFetching: true,
            }
        case actions.RECEIVE_PAGE_CONTENT:
            return {
                isFetching: false,
                payload: {...action.payload.page_content}
            }
        default:
            return state
    }
}
