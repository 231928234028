import React from 'react';
import { Route } from 'react-router-dom';

const TopPicksContainer = React.lazy(() => import('./container'));
export const route = (
  <Route
    exact
    render={props => <TopPicksContainer {...props} />}
    path="/top-picks"
    key="top-picks"
  />
);
