import React from "react"
import { Typography } from "../../../atoms"

export function Summary({ analysisDescription }) {
    if (!analysisDescription) return null

    return <>
        <Typography className="o-panel__title" component="h2" variant="h3">Summary</Typography>
        <div className="rte-content" dangerouslySetInnerHTML={{ __html: analysisDescription }} />
    </>
}
