import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// import Scroll from "react-scroll"
// import { debounce } from "lodash"
// import { getManualCrunch } from "../../../redux/actions"
// import { openPlaid } from "../../../redux/actions/__deprecated-plaid"
// import { getEnvData } from "../../../redux/actions/env"
import { logEvent } from '../../../redux/actions/__deprecated-actions';
import { openPlaid } from '../../../redux/actions/plaid';
import { Home } from './home';
import { data } from './fixtures';
import { isSnap } from '../../../config/api-services-config';

import './home.scss';

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      isModalVisible: false
      // PlaidComponent: null,
      // isContentWaypoint: false,
    };
    // this.onScroll = debounce(this.onScroll, 10)
  }

  // componentDidMount() {
  //     window.addEventListener('scroll', this.onScroll)
  // }

  // componentWillUnmount() {
  //     window.removeEventListener('scroll', this.onScroll)
  // }

  // onScroll = () => this.isContentWaypoint()

  // handlePageNavClick = elementName => {
  //     Scroll.scroller.scrollTo(elementName, {smooth: true, offset: -100})
  // }

  handlePlaidClick = async () => {
    this.props.logEvent(
      'homepage-crunch-spending-history-click',
      'homepage-crunch-spending-history-click'
    );

    openPlaid();

    // window.plaidHandler.open()

    // if (this.state.PlaidComponent === null) {
    //     const Component = await import("../../organisms/plaid")
    //
    //     this.setState(prevState => ({
    //         ...prevState,
    //         PlaidComponent: Component.default
    //     }), () => {
    //         const interval = () => {
    //             if (window.plaidHandler) {
    //                 window.plaidHandler.open()
    //                 clearInterval(id)
    //             }
    //         }
    //         const id = setInterval(interval, 1000)
    //     })
    // } else {
    //     if (window.plaidHandler) {
    //         window.plaidHandler.open()
    //         this.props.logEvent(
    //             "homepage-crunch-your-spending-history-plaid-open",
    //             "homepage-crunch-your-spending-history-plaid-open",
    //         )
    //     }
    // }
  };

  handleCreditCardsClick = _ => {
    this.props.logEvent(
      'homepage-explore-all-credit-cards-click',
      'homepage-explore-all-credit-cards-click'
    );

    this.props.history.push('/credit-cards');
  };

  handleVideoClick = _ => {
    this.props.logEvent('homepage-video-click', 'homepage-video-click');

    this.setState(
      prevState => ({
        ...prevState,
        isModalVisible: !prevState.isModalVisible
      }),
      () => {
        this.props.logEvent('homepage-video-modal-open', 'homepage-video-modal-open');
      }
    );
  };

  handleModalCloseClick = () => {
    this.props.logEvent('homepage-video-modal-close', 'homepage-video-modal-close');

    this.setState(prevState => ({
      ...prevState,
      isModalVisible: !prevState.isModalVisible
    }));
  };

  renderMetaTags() {
    const title = 'CardCruncher: Easiest way to find your next credit card';
    const description = `CardCruncher is the only credit card recommendation engine that uses your real spending to automatically find your best credit card. It's free to use and returns results in just seconds. Get your next credit card with certain that it's the best for you.`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    );
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        {!isSnap && (
          <Home
            {...this.props}
            {...this.state}
            data={data}
            onCreditCardsClick={this.handleCreditCardsClick}
            onVideoClick={this.handleVideoClick}
            onModalCloseClick={this.handleModalCloseClick}
            onCrunchHistoryClick={this.handlePlaidClick}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    crunch: state.crunch,
    envData: state.envData,
    plaid: state.plaid,
    user_has_connected_plaid: state.root.user_has_connected_plaid
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logEvent: (eventType, eventLabel, eventValue) => {
      dispatch(logEvent(eventType, eventLabel, eventValue));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
