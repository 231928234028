import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getCardDetails } from '../../../../redux/actions/card-details';
import { exchangeData, getCardsList } from '../../../../redux/actions/cards-list';
import { calculateTotalsFromComponents } from '../../../../utilities';
import { openPlaid } from '../../../../redux/actions/__deprecated-plaid';
import { logEvent } from '../../../../redux/actions/__deprecated-actions';
import { manualCrunch } from '../../../../redux/actions/__deprecated-manual-crunch';
import { crunchStarted } from '../../../../redux/actions/__deprecated-plaid';
import { __fetchFakeCrunchData } from '../../../../redux/actions/__deprecated-fake-crunch-data';
import EmailCollectionContainer from '../../../specific/email-collection/container';
import { isSnap } from '../../../../config/api-services-config';
import { getCardRelativeURL } from '../../../../utilities/utils';
import { Typography } from '../../../atoms';
import { Hero } from '../../../organisms';
import { Row } from '../../../templates';

import Cards from './cards';

import '../cards.scss';

function CardsContainer({ actions, cardsList, root }) {
  const [cards, setCards] = React.useState(null);
  // const [heroHeight, setHeroHeight] = React.useState(null);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [paginationSize, setPagination] = React.useState(11);
  const [searchValue, setSearchValue] = React.useState('');

  const handleSearchChange = event => {
    const newList = [];
    const value = event.target.value;

    if (value !== '') {
      cardsList.payload.forEach(card => {
        const cardName = card.full_name.toLowerCase();
        const cardIsMatchingSearch = cardName.indexOf(value.toLowerCase()) >= 0;

        if (cardIsMatchingSearch) {
          newList.push(card);
        }
      });

      setCards(newList);
    } else {
      setCards(cardsList.payload);

      if (paginationSize !== 11) {
        setPagination(11);
      }
    }

    setSearchValue(value);
  };

  const handleAdvertiserClick = () => setModalOpen(true);

  const handleApplyNowClick = card => {
    actions.logEvent(
      'credit-cards-apply-now-click',
      `credit-cards-apply-now-${card.id} | ${card.slug}`
    );
  };

  const handleConnectClick = async () => {
    actions.logEvent('click', 'Credit Cards', 'Plaid connect');
    openPlaid();
  };

  const handleLoadMoreClick = () => {
    const cardsListSize = cardsList.payload.length;

    if (paginationSize <= cardsListSize) {
      const newPaginationSize = paginationSize + 11;
      setPagination(newPaginationSize);
    }
  };

  const handleModalClose = () => setModalOpen(false);

  const handleViewDetailsClick = (event, card) => {
    event.preventDefault();
    actions.getCardDetails(card.id).then(() => {
      actions.logEvent(
        'credit-cards-view-details-click',
        `credit-cards-view-details-${card.id} | ${card.slug}`
      );
    });
  };

  // const handleWindowScroll = () => {
  //   const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

  //   if (winScroll > heroHeight + 50) {
  //     document.getElementsByTagName('body')[0].classList.add('--fixed');
  //   } else {
  //     document.getElementsByTagName('body')[0].classList.remove('--fixed');
  //   }
  // };

  const renderMetaTags = () => (
    <Helmet>
      <title>Rewards Credit Cards List | CardCruncher</title>
      <meta
        name="description"
        content="Up-to-date credit cards list with all top rewards cards.
                Compare credit cards with CardCruncher to see how each matches
                with your real spending."
      />
    </Helmet>
  );

  const renderSeoLinks = () => {
    if (cardsList.payload === null) return null;

    return (
      <ul style={{ display: 'none' }}>
        {cardsList.payload.map(card => {
          const url = getCardRelativeURL(card.issuer, card.slug, card.id);
          return (
            <li>
              <a href={url}>{card.full_name}</a>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderLoader = () => {
    const { crunch_attempts, crunch_error } = root;
    let message = "We're now processing your transaction history";
    if (crunch_attempts > 2) {
      message = 'Our engine is now matching which cards will return you the most cash value';
    }
    if (crunch_attempts > 4) {
      message = 'Your optimal credit card is just seconds away';
    }

    if (crunch_error) {
      message = 'Something went wrong. Please try again.';
    }

    return (
      <Hero className="p-top-picks__hero" size="md" bg>
        <Hero.Content>
          <Row>
            <Row.Col sm="8" md="6" lg="6">
              <Typography component="h1" variant="h1">
                {message}
                {crunch_error !== true && (
                  <>
                    <span />
                    <span />
                    <span />
                  </>
                )}
              </Typography>
            </Row.Col>
          </Row>
        </Hero.Content>
      </Hero>
    );
  };

  React.useEffect(() => {
    if (root.crunch_data === null) {
      if (cardsList.payload === null && cardsList.error === null) {
        actions.getCards();
      }
    }

    if (cardsList.payload !== null) {
      setCards(cardsList.payload);
    }

    // if (cardsList.payload !== null && cards === null) {
    //   setCards(cardsList.payload);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsList.payload]);

  React.useEffect(() => {
    const crunchStatus = localStorage.getItem('__crunch-status');
    if (crunchStatus === 'crunching' && root.crunch_in_progress === false) {
      const { cookies, fake_crunch_profile, login_token } = root;
      const { utm_campaign, utm_medium, utm_source } = cookies;
      const utmObj = {
        utm_campaign: '',
        utm_medium: '',
        utm_source: ''
      };

      if (utm_campaign !== undefined) {
        utmObj.utm_campaign = utm_campaign;
      }

      if (utm_medium !== undefined) {
        utmObj.utm_medium = utm_medium;
      }

      if (utm_source !== undefined) {
        utmObj.utm_source = utm_source;
      }

      actions.fakeCrunch(fake_crunch_profile, login_token, false, false, utmObj); // done
    }

    document.getElementsByTagName('main')[0].classList.add('--remove-min-height');
    // window.addEventListener('scroll', handleWindowScroll);

    if (root.crunch_data !== null) {
      const crunch_data = root.crunch_data;
      const newCards = [];

      for (let i = 0; i < crunch_data.by_card.length; i++) {
        const card = crunch_data.by_card[i];
        const newCard = card.card;

        if (card.by_component) {
          const { transactionCrunchValue, totalBonus, totalFees } = calculateTotalsFromComponents(
            card.by_component
          );

          newCard['by_component'] = card.by_component;
          newCard['crunch_values'] = {
            transactionCrunchValue: transactionCrunchValue,
            totalBonus: totalBonus,
            totalFees: totalFees
          };
        }

        if (card.total_crunch_value) {
          newCard['crunch_values'] = {
            ...newCard.crunch_values,
            total_crunch_value: card.total_crunch_value
          };
        }

        newCards.push(newCard);
      }

      actions.exchangeData(newCards);
    }

    return () => {
      document.getElementsByTagName('main')[0].classList.remove('--remove-min-height');
      // window.removeEventListener('scroll', handleWindowScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // window.addEventListener('scroll', handleWindowScroll);

    if (root.crunch_data !== null) {
      const crunch_data = root.crunch_data;
      const newCards = [];

      for (let i = 0; i < crunch_data.by_card.length; i++) {
        const card = crunch_data.by_card[i];
        const newCard = card.card;

        if (card.by_component) {
          const { transactionCrunchValue, totalBonus, totalFees } = calculateTotalsFromComponents(
            card.by_component
          );

          newCard['by_component'] = card.by_component;
          newCard['crunch_values'] = {
            transactionCrunchValue: transactionCrunchValue,
            totalBonus: totalBonus,
            totalFees: totalFees
          };
        }

        if (card.total_crunch_value) {
          newCard['crunch_values'] = {
            ...newCard.crunch_values,
            total_crunch_value: card.total_crunch_value
          };
        }

        newCards.push(newCard);
      }

      actions.exchangeData(newCards);
    }

    return () => {
      // window.removeEventListener('scroll', handleWindowScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root.crunch_data]);

  return (
    <>
      {renderMetaTags()}
      {isSnap ? (
        renderSeoLinks()
      ) : root.crunch_in_progress ? (
        renderLoader()
      ) : (
        <>
          <Cards
            cards={cardsList}
            filteredCards={cards}
            isFakeCrunched={root.crunch_data !== null && !root.user_has_connected_plaid}
            isModalOpen={isModalOpen}
            user_has_connected_plaid={root.user_has_connected_plaid}
            searchValue={searchValue}
            paginationSize={paginationSize}
            onSearchChange={handleSearchChange}
            onViewDetailsClick={handleViewDetailsClick}
            onConnectClick={handleConnectClick}
            onAdvertiserClick={handleAdvertiserClick}
            onApplyNowClick={handleApplyNowClick}
            onManualCrunchSubmit={spendings => actions.manualCrunch(spendings)}
            onModalClose={handleModalClose}
            onLoadMoreClick={handleLoadMoreClick}
          />
          <EmailCollectionContainer />
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    cardsList: state.cardsList,
    root: state.root
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    exchangeData: payload => dispatch(exchangeData(payload)),
    fakeCrunch: (
      fake_crunch_profile,
      login_token,
      forcePointsRedemption,
      skipFirstYear,
      utmObj
    ) => {
      dispatch(crunchStarted());
      dispatch(
        __fetchFakeCrunchData(
          fake_crunch_profile,
          login_token,
          forcePointsRedemption,
          skipFirstYear,
          utmObj
        )
      ); // done
    },
    getCards: () => dispatch(getCardsList()),
    getCardDetails: cardId => dispatch(getCardDetails(cardId)),
    manualCrunch: profile => dispatch(manualCrunch(profile)),
    logEvent: (eventType, eventLabel, eventValue) => {
      dispatch(logEvent(eventType, eventLabel, eventValue));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CardsContainer);
