import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './typography.scss';

function Typography(props) {
  const classes = cx(
    { [`a-${props.variant}`]: !!props.variant },
    { [`--${props.weight}`]: !!props.weight },
    { [`--${props.transform}`]: !!props.transform },
    { [`--color-${props.accent}`]: !!props.accent },
    props.className
  );
  const Component = props.component || 'span';

  return (
    <Component className={classes} onClick={props.onClick}>
      {props.children}
    </Component>
  );
}

export default Typography;

Typography.defaultProps = {
  variant: 'body1',
  onClick: () => {}
};

Typography.propTypes = {
  accent: PropTypes.string,
  component: PropTypes.elementType,
  transform: PropTypes.oneOf(['uppercase']),
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'subtitle3',
    'badge',
    'body1',
    'body2',
    'caption',
    'overline'
  ]),
  weight: PropTypes.oneOf(['500', '600', '700'])
};
