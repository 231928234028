import { push } from 'react-router-redux';
import { fetchCrunchData } from './__deprecated-actions';

let url = document.location.protocol + '//' + document.location.host + '/api/';
if (process.env.NODE_ENV === 'development') {
  url = 'http://127.0.0.1:8000/';
}

export const plaidLoaded = () => dispatch => {
  dispatch({ type: 'PLAID_LOADED' });
};

export const openPlaidModal = () => ({
  type: 'OPEN_PLAID_MODAL'
});

export const closePlaidModal = () => ({
  type: 'CLOSE_PLAID_MODAL'
});

export const openPlaid = () => {
  if (window.plaidHandler) {
    window.plaidHandler.open();
  }

  return {
    type: 'OPEN_PLAID'
  };
};

export const closePlaid = () => {
  if (window.plaidHandler) {
    window.plaidHandler.exit();
  }

  return {
    type: 'CLOSE_PLAID'
  };
};

export const plaidExit = (error, metadata) => {
  return {
    type: 'PLAID_EXIT',
    error,
    metadata
  };
};

export const exchangePlaidTokenSuccess = login_token => ({
  type: 'EXCHANGE_PLAID_TOKEN_SUCCESS',
  login_token: login_token
});

export const exchangePlaidToken = (public_token, metadata, utmObj) => {
  return (dispatch, getState) => {
    // dispatch("/top-picks")
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    // If the user is logged in, this item will be added to their existing
    // account
    const { login_token } = getState().root;
    if (login_token) {
      headers['Authorization'] = 'Token ' + login_token;
    }

    fetch(`${url}api/connect/`, {
      method: 'POST',
      body: JSON.stringify({
        plaid_public_token: public_token,
        metadata
      }),
      headers
    })
      .then(response => response.json())
      .then(json => {
        const login_token = json.auth_token;
        dispatch(exchangePlaidTokenSuccess(login_token));
        dispatch(fetchCrunchData(utmObj));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const connectAccount = eventSource => {
  return (dispatch, getState) => {
    // dispatch(logEvent(eventSource, eventSource));

    if (getState().root.user_has_connected_plaid) {
      dispatch(push('/top-picks'));
    } else {
      dispatch(openPlaidModal());
    }
  };
};

export const crunchStarted = () => ({
  type: 'CRUNCH_STARTED'
});

export const plaidSuccess = (public_token, metadata, utmObj) => dispatch => {
  dispatch(crunchStarted());
  dispatch(push('/top-picks'));
  dispatch(exchangePlaidToken(public_token, metadata, utmObj));
};

// export const plaidSuccess = (public_token, metadata) => {
//     console.log("plaidSuccess HERE", public_token, metadata)
//     return dispatch => {
//         console.log("plaidSuccess -----", dispatch)
//         dispatch(crunchStarted())
//         dispatch("/top-picks")
//         dispatch(push('/top-picks'))
//         dispatch(exchangePlaidToken(public_token, metadata))
//     }
// }

// Header = [
//     {
//         event_label: "Header Blog",
//         event_value: "Credit Cards click",
//     },
//     {
//         event_label: "Header Blog",
//         event_value: "How it works click",
//     },
//     {
//         event_label: "Header Blog",
//         event_value: "Start Crunching click",
//     }
// ]

// Footer = [
//     {
//         event_label: "Footer Blog",
//         event_value: "Reviews click"
//     },
//     {
//         event_label: "Footer Blog",
//         event_value: "Advice click"
//     },
//     {
//         event_label: "Footer Blog",
//         event_value: "Best Credit Cards click"
//     },
//     {
//         event_label: "Footer Blog",
//         event_value: "Security click"
//     },
//     {
//         event_label: "Footer Blog",
//         event_value: "How it works"
//     },
//     {
//         event_label: "Footer Blog",
//         event_value: "Contact us"
//     }
// ]
