import React from 'react';
import { withCookies } from 'react-cookie';
import Modal from '../modal';
import ManualCrunch from '../manual-crunch';

const createScript = (resolve, reject) => {
  const script = document.createElement('script');
  script.id = 'plaid-script';
  script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
  script.async = true;
  script.addEventListener('load', () => resolve());
  script.addEventListener('error', e => reject(e));

  return script;
};

const injectScript = () =>
  new Promise((resolve, reject) => {
    const scriptTag = document.getElementById('plaid-script');
    if (!scriptTag) {
      const script = createScript(resolve, reject);
      document.body.appendChild(script);
    }
  });

const createPlaid = ({
  env,
  public_key,
  webhook_url,
  handleLoad,
  handleSuccess,
  handleExit,
  handleEvent
}) =>
  window.Plaid.create({
    apiVersion: 'v2',
    clientName: 'CardCruncher',
    env: env,
    product: ['transactions'],
    key: public_key,
    webhook: webhook_url,
    onLoad: () => handleLoad(),
    onSuccess: (token, metadata) => handleSuccess(token, metadata),
    onExit: (error, metadata) => handleExit(error, metadata),
    onEvent: (name, metadata) => handleEvent(name, metadata)
  });

const ManualCrunchModal = ({ isOpen, onClose, onManualCrunchSubmit }) => (
  <Modal className="o-manual-crunch-modal" isOpen={isOpen} onRequestClose={onClose}>
    <ManualCrunch onSubmit={onManualCrunchSubmit} withScroll={true} />
  </Modal>
);

const Plaid = ({
  cookies,
  envData,
  getEnvData,
  history,
  openPlaid,
  closePlaid,
  plaid,
  plaidExit,
  plaidLoaded,
  plaidSuccess,
  manualCrunch,
  root
}) => {
  const [modalVisible, setModalVisibility] = React.useState(false);

  // Action handlers
  const handleLoad = () => plaidLoaded();

  const handleSuccess = (token, metadata) => {
    const { utm_campaign, utm_source, utm_medium } = root.cookies;
    const utmObj = {
      utm_campaign: '',
      utm_source: '',
      utm_medium: ''
    };

    if (utm_campaign !== undefined) {
      utmObj.utm_campaign = utm_campaign;
    }

    if (utm_source !== undefined) {
      utmObj.utm_source = utm_source;
    }

    if (utm_medium !== undefined) {
      utmObj.utm_medium = utm_medium;
    }

    plaidSuccess(token, metadata, utmObj);
  };

  const handleEvent = (eventName, metadata) => {
    const isExitEvent = eventName === 'EXIT';
    const isOpenEvent = eventName === 'OPEN';
    const locationHash = history.location.hash;
    const bodyTag = document.getElementsByTagName('body')[0];

    if (isOpenEvent) {
      bodyTag.classList.remove('is-plaid-closed');
      bodyTag.classList.add('is-plaid-open');

      if (locationHash !== '#plaid-connect') {
        history.push('#plaid-connect');
      }
    }

    if (isExitEvent) {
      bodyTag.classList.remove('is-plaid-open');
      bodyTag.classList.add('is-plaid-closed');

      if (locationHash === '#plaid-connect') {
        history.push(history.location.pathname);
        setModalVisibility(true);
      }
    }
  };

  const handleExit = (error, metadata) => plaidExit(error, metadata);

  const handleModalClose = () => setModalVisibility(false);

  const handleManualCrunchSubmit = spendings => {
    setModalVisibility(false);
    setTimeout(() => manualCrunch(spendings), 200);
  };

  React.useEffect(() => {
    if (envData.payload === null) {
      getEnvData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (envData.payload && !plaid.isLoaded) {
      injectScript().then(() => handleLoad());
    }

    if (plaid.isLoaded && !window.plaidHandler) {
      window.plaidHandler = createPlaid({
        ...envData.payload,
        handleLoad: handleLoad,
        handleSuccess: handleSuccess,
        handleExit: handleExit,
        handleEvent: handleEvent
      });
    }

    if (plaid.isLoaded && window.plaidHandler) {
      if (history.location.hash === '#plaid-connect' && !plaid.isOpen) {
        openPlaid();
      }

      if (history.location.hash !== '#plaid-connect' && plaid.isOpen) {
        closePlaid();
      }
    }

    if (modalVisible === false) {
      document.getElementsByTagName('body')[0].classList.remove('ReactModal__Body--open');
    }
  });

  return (
    modalVisible && (
      <ManualCrunchModal
        isOpen={modalVisible}
        onClose={handleModalClose}
        onManualCrunchSubmit={handleManualCrunchSubmit}
      />
    )
  );
};

export default withCookies(Plaid);
