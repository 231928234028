import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ScrollBar from "react-perfect-scrollbar"
// import { Button } from "../../atoms"
import { RangeSlider } from "../../molecules"

import EntertainmentIcon from "../../../assets/images/icons/icon-entertainment.svg"
import GasStationIcon from "../../../assets/images/icons/icon-gas-stations.svg"
import GroceriesIcon from "../../../assets/images/icons/icon-groceries.svg"
import PharmacyIcon from "../../../assets/images/icons/icon-pharmacy.svg"
import RestaurantsIcon from "../../../assets/images/icons/icon-restaurants.svg"
import TravelIcon from "../../../assets/images/icons/icon-travel.svg"
import OtherIcon from "../../../assets/images/icons/icon-other.svg"

import "./manual-crunch.scss"
import "react-perfect-scrollbar/dist/css/styles.css";

function ManualCrunch(props) {
    const { fake_crunch_profile } = props.root
    const [values, setValues] = React.useState({
        dining: fake_crunch_profile ? fake_crunch_profile.dining : props.values.dining,
        travel: fake_crunch_profile ? fake_crunch_profile.travel : props.values.travel,
        groceries: fake_crunch_profile ? fake_crunch_profile.groceries : props.values.groceries,
        gas_stations: fake_crunch_profile ? fake_crunch_profile.gas_stations : props.values.gas_stations,
        entertainment: fake_crunch_profile ? fake_crunch_profile.entertainment : props.values.entertainment,
        pharmacy: fake_crunch_profile ? fake_crunch_profile.pharmacy : props.values.pharmacy,
        other: fake_crunch_profile ? fake_crunch_profile.other : props.values.other,
    })

    const handleChange = name => value => {
        setValues({ ...values, [name]: value })
    }

    const handleClick = event => {
        event.preventDefault()
        props.onSubmit(values)
    }

    const options = {
        suppressScrollX: true,
        suppressScrollY: false,
    }

    return (
        <form className="o-manual-crunch">
            <div className="form__header">
                <h3>Find your perfect card</h3>
                <p>Set the sliders to reflect your monthly spending on each category.</p>
            </div>
            <div className="form__body">
                {props.withScroll ? (
                    <div className="scroll-wrapper">
                        <ScrollBar options={options}>
                        <RangeSlider
                            key="travel"
                            name="Travel"
                            value={values.travel}
                            icon={TravelIcon}
                            max={1000}
                            onChange={handleChange("travel")}
                        />
                        <RangeSlider
                            key="dining"
                            name="Restaurants"
                            value={values.dining}
                            icon={RestaurantsIcon}
                            max={1000}
                            onChange={handleChange("dining")}
                        />
                        <RangeSlider
                            key="gas_stations"
                            name="Gas Stations"
                            value={values.gas_stations}
                            icon={GasStationIcon}
                            max={1000}
                            onChange={handleChange("gas_stations")}
                        />
                        <RangeSlider
                            key="groceries"
                            name="Groceries"
                            value={values.groceries}
                            icon={GroceriesIcon}
                            max={1000}
                            onChange={handleChange("groceries")}
                        />
                        <RangeSlider
                            key="entertainment"
                            name="Entertainment"
                            value={values.entertainment}
                            icon={EntertainmentIcon}
                            max={1000}
                            onChange={handleChange("entertainment")}
                        />
                        <RangeSlider
                            key="pharmacy"
                            name="Pharmacy"
                            value={values.pharmacy}
                            icon={PharmacyIcon}
                            max={1000}
                            onChange={handleChange("pharmacy")}
                        />
                        <RangeSlider
                            key="other"
                            name="Other"
                            value={values.other}
                            icon={OtherIcon}
                            max={1000}
                            onChange={handleChange("other")}
                        />
                        </ScrollBar>
                    </div>
                ) : (
                    <>
                    <RangeSlider
                        key="travel"
                        name="Travel"
                        value={values.travel}
                        icon={TravelIcon}
                        max={1000}
                        onChange={handleChange("travel")}
                    />
                    <RangeSlider
                        key="dining"
                        name="Restaurants"
                        value={values.dining}
                        icon={RestaurantsIcon}
                        max={1000}
                        onChange={handleChange("dining")}
                    />
                    <RangeSlider
                        key="gas_stations"
                        name="Gas Stations"
                        value={values.gas_stations}
                        icon={GasStationIcon}
                        max={1000}
                        onChange={handleChange("gas_stations")}
                    />
                    <RangeSlider
                        key="groceries"
                        name="Groceries"
                        value={values.groceries}
                        icon={GroceriesIcon}
                        max={1000}
                        onChange={handleChange("groceries")}
                    />
                    <RangeSlider
                        key="entertainment"
                        name="Entertainment"
                        value={values.entertainment}
                        icon={EntertainmentIcon}
                        max={1000}
                        onChange={handleChange("entertainment")}
                    />
                    <RangeSlider
                        key="pharmacy"
                        name="Pharmacy"
                        value={values.pharmacy}
                        icon={PharmacyIcon}
                        max={1000}
                        onChange={handleChange("pharmacy")}
                    />
                    <RangeSlider
                        key="other"
                        name="Other"
                        value={values.other}
                        icon={OtherIcon}
                        max={1000}
                        onChange={handleChange("other")}
                    />
                    </>
                )}
            </div>
            <div className="form__footer">
                <a
                    href='/top-picks'
                    className='a-button a-button--primary a-button--lg-size a-button--block'
                    onClick={handleClick}
                >
                    Crunch Now
                </a>
            </div>
        </form>
    )
}

function mapStateToProps(state) {
    return {
        root: state.root
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualCrunch)

ManualCrunch.defaultProps = {
    values: {
        dining: 200,
        travel: 80,
        groceries: 200,
        gas_stations: 30,
        entertainment: 50,
        pharmacy: 30,
        other: 130,
    },
    withScroll: false,
}

ManualCrunch.propTypes = {
    values: PropTypes.shape({
        dining: PropTypes.any.isRequired,
        travel: PropTypes.any.isRequired,
        groceries: PropTypes.any.isRequired,
        gas_stations: PropTypes.any.isRequired,
        entertainment: PropTypes.any.isRequired,
        pharmacy: PropTypes.any.isRequired,
        other: PropTypes.any.isRequired,   
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
}
