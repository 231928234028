import React from "react"
import cx from "classnames"
// import styled from "styled-components"

import "./paper.scss"

// const BG_COLOR = {
//     light: "#f8fbfc",
// }

// const BORDER_RADIUS = {
//     xs: "0.1875rem",
//     md: "0.25rem",
//     lg: "0.3125rem",
// }

// const SIZE_UNIT = 0.5
// const SIZE = {
//     1: SIZE_UNIT * 1 + "rem",
//     2: SIZE_UNIT * 2 + "rem",
//     3: SIZE_UNIT * 3 + "rem",
//     4: SIZE_UNIT * 4 + "rem",
//     5: SIZE_UNIT * 5 + "rem",
//     6: SIZE_UNIT * 6 + "rem",
//     7: SIZE_UNIT * 7 + "rem",
//     8: SIZE_UNIT * 8 + "rem",
//     9: SIZE_UNIT * 9 + "rem",
//     10: SIZE_UNIT * 10 + "rem",
//     11: SIZE_UNIT * 11 + "rem",
//     12: SIZE_UNIT * 12 + "rem",
// }

function Paper(props) {
    const classes = cx(
        props.className,
        "o-paper",
        { [`--bg-light`]: !!props.bgLight },
    )

    // const ComputedPaper = styled.div`
    //     margin-top: ${props.mt && SIZE[props.mt]};
    //     margin-bottom: ${props.mb && SIZE[props.mb]};
    //     margin-right: ${props.mr && SIZE[props.mr]};
    //     margin-left: ${props.ml && SIZE[props.ml]};
    //     padding-top: ${props.pt && SIZE[props.pt]};
    //     padding-bottom: ${props.pb && SIZE[props.pb]};
    //     padding-right: ${props.pr && SIZE[props.pr]};
    //     padding-left: ${props.pl && SIZE[props.pl]};
    //     background-color: ${props.bgColor && BG_COLOR[props.bgColor]};
    //     border-radius: ${props.borderRadius && BORDER_RADIUS[props.borderRadius]};
    // `

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}

export default Paper
