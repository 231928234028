import actions from "../actions"

export function cardDetailsReducer(state = {
    isFetching: false,
    payload: null
}, action) {
    switch (action.type) {
        case actions.REQUEST_CARD_DETAILS:
            return {
                ...state,
                isFetching: true,
            }
        case actions.RECEIVE_CARD_DETAILS:
            return {
                ...state,
                isFetching: false,
                payload: action.payload,
            }
        case actions.RESET_CARD_DETAILS:
            return {
                isFetching: false,
                payload: null,
            }
        default:
            return state;
    }
}
