import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Tag } from "../../atoms"

import "./tag-list.scss";

function TagList(props) {
    const classes = cx(
        "m-tag-list",
        `m-tag-list--${props.direction}`
    );

    return (
        <div className={classes}>
            {props.items.map(item =>
                <Tag
                    key={item.name}
                    name={item.name}
                    value={item.value}
                    onClick={props.onClick}
                />
            )}
        </div>
    )
}

export default TagList;

TagList.defaultProps = {
    direction: "horizontal",
    onClick: () => {}
}

TagList.propTypes = {
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ),
    onClick: PropTypes.func.isRequired
}
