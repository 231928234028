import React from 'react';
import { Button, Input, Typography } from '../../../atoms';
import { FormRow, Loader } from '../../../molecules';
import {
  AdvertiserDisclosureModal,
  Hero,
  ManualCrunch,
  Offer,
  Panel,
  PlaidConnect,
  Sidebar
} from '../../../organisms';
import { Container, Row } from '../../../templates';

import SearchIcon from '../../../../assets/images/icons/icon-search.svg';
import WatermarkLogo from '../../../../assets/images/app-logo--watermark.svg';

function CardsList({
  filteredCards,
  isFakeCrunched,
  paginationSize,
  user_has_connected_plaid,
  onApplyNowClick,
  onConnectClick,
  onViewDetailsClick
}) {
  if (!filteredCards) {
    return <Loader isVisible={true} variant="fixed" />;
  }

  if (filteredCards.length === 0) {
    return <p>No results found.</p>;
  }

  return (
    <>
      {filteredCards.slice(0, paginationSize).map((item, index) => {
        const isBestChoice = index === 0;

        return (
          <Offer
            {...item}
            key={item.id}
            isBestChoice={isBestChoice}
            isFakeCrunched={isFakeCrunched}
            full_name={item.full_name}
            user_has_connected_plaid={user_has_connected_plaid}
            onApplyNowClick={() => onApplyNowClick(item)}
            onConnectClick={onConnectClick}
            onViewDetailsClick={event => onViewDetailsClick(event, item)}
          />
        );
      })}
    </>
  );
}

const PageHero = ({
  isFakeCrunched,
  isModalOpen,
  searchValue,
  user_has_connected_plaid,
  onAdvertiserClick,
  onModalClose,
  onSearchChange
}) => (
  <Hero size="md" bg>
    <Hero.Content>
      <AdvertiserDisclosureModal
        isOpen={isModalOpen}
        onClick={onAdvertiserClick}
        onClose={onModalClose}
      />
      <Typography component="h1" variant="h1">
        {isFakeCrunched || user_has_connected_plaid
          ? 'Your top ranked cards'
          : 'Browse credit cards'}
      </Typography>
      <FormRow>
        <Input
          aria-label="Search Cards Input"
          placeholder="Search cards"
          name="search-cards"
          type="text"
          value={searchValue}
          size="xlg"
          onChange={onSearchChange}
        />
        <Button variant="primary" size="xlg" ariaLabel="Search Cards Button">
          <img src={SearchIcon} alt="" />
        </Button>
      </FormRow>
      <Typography component="p" variant="body2">
        We select from over 150 top credit cards.
      </Typography>
    </Hero.Content>
    <img className="o-hero__watermark" src={WatermarkLogo} alt="" />
  </Hero>
);

const PageContent = ({
  cards,
  filteredCards,
  searchValue,
  isFakeCrunched,
  paginationSize,
  user_has_connected_plaid,
  onApplyNowClick,
  onConnectClick,
  onViewDetailsClick,
  onLoadMoreClick
}) => {
  const renderPagination = () => {
    if (filteredCards === null) return null;
    if (filteredCards.length === 0) return null;
    if (paginationSize > filteredCards.length) return null;

    return (
      <div className="p-credit-cards__pagination">
        <Button variant="outline-dark" onClick={onLoadMoreClick}>
          Load More
        </Button>
      </div>
    );
  };

  return (
    <div className="p-credit-cards__content">
      <CardsList
        cards={cards}
        filteredCards={filteredCards}
        searchValue={searchValue}
        paginationSize={paginationSize}
        isFakeCrunched={isFakeCrunched}
        user_has_connected_plaid={user_has_connected_plaid}
        onApplyNowClick={onApplyNowClick}
        onConnectClick={onConnectClick}
        onViewDetailsClick={onViewDetailsClick}
      />
      {renderPagination()}
    </div>
  );
};

const PageSidebar = ({ user_has_connected_plaid, onConnectClick, onManualCrunchSubmit }) => (
  <Sidebar className="p-credit-cards__aside">
    <PlaidConnect userHasConnectedPlaid={user_has_connected_plaid} onClick={onConnectClick} />
    {!user_has_connected_plaid && (
      <Panel className="with-gradient with-gradient--1">
        <Panel.Body>
          <ManualCrunch expandable={true} onSubmit={onManualCrunchSubmit} withCrunch />
        </Panel.Body>
      </Panel>
    )}
  </Sidebar>
);

function Cards({
  cards,
  filteredCards,
  isFakeCrunched,
  isModalOpen,
  user_has_connected_plaid,
  searchValue,
  paginationSize,
  onAdvertiserClick,
  onApplyNowClick,
  onConnectClick,
  onLoadMoreClick,
  onManualCrunchSubmit,
  onModalClose,
  onSearchChange,
  onViewDetailsClick
}) {
  return (
    <div className="p-credit-cards --bg-gray">
      <PageHero
        isModalOpen={isModalOpen}
        searchValue={searchValue}
        isFakeCrunched={isFakeCrunched}
        user_has_connected_plaid={user_has_connected_plaid}
        onAdvertiserClick={onAdvertiserClick}
        onModalClose={onModalClose}
        onSearchChange={onSearchChange}
      />
      <Container>
        <Row>
          <Row.Col md="8" lg="8">
            <PageContent
              cards={cards}
              filteredCards={filteredCards}
              searchValue={searchValue}
              paginationSize={paginationSize}
              isFakeCrunched={isFakeCrunched}
              user_has_connected_plaid={user_has_connected_plaid}
              onApplyNowClick={onApplyNowClick}
              onConnectClick={onConnectClick}
              onViewDetailsClick={onViewDetailsClick}
              onLoadMoreClick={onLoadMoreClick}
            />
          </Row.Col>
          <Row.Col md="4" lg="4">
            <PageSidebar
              user_has_connected_plaid={user_has_connected_plaid}
              onConnectClick={onConnectClick}
              onManualCrunchSubmit={onManualCrunchSubmit}
            />
          </Row.Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cards;
