import React from "react"
import { Redirect, Route } from "react-router-dom"

const ErrorContainer = React.lazy(() => import("./container"))
export const route = 
    <Route
        render={({
            history: { location: {pathname, search, hash} }
        }) => pathname.slice(-1) === "/" ? (
            <Redirect to={`${pathname.slice(0, -1)}${search}${hash}`} key={`${pathname.slice(0, -1)}${search}${hash}`} />
        ) : (
            <Route render={props => <ErrorContainer {...props} />} status={404} key="error" />
        )}
    key="redirect"
/>
