import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./input.scss"

function Input(props) {
    const classes = cx(
        "a-input",
        { [`--${props.size}-size`]: !!props.size },
        props.className,
    )

    return (
        <input
            {...props}
            className={classes}
            type={props.type}
            onChange={event => props.onChange(event)}
        />
    )
}

export default Input

Input.defaultProps = {
    onChange: () => {}
}

Input.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    onChange: PropTypes.func.isRequired,
}
