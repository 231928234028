import { getManualCrunchApi } from '../../api';

const fakeCrunchDataFailure = error => ({
  type: 'FAKE_CRUNCH_FAILURE'
  // error: {
  //   message: error.detail
  // }
});

const fakeCrunchDataSuccess = ({ auth_token, crunch_data }) => {
  return {
    auth_token: auth_token,
    crunch_data: {
      by_card: crunch_data.by_card,
      created: crunch_data.created
    },
    type: 'FAKE_CRUNCH_SUCCESS'
  };
};

export const __fetchFakeCrunchData = (
  fake_crunch_profile,
  login_token,
  forcePointsRedemption,
  skipFirstYear,
  utmObj
) => dispatch => {
  localStorage.setItem('__crunch-status', 'crunching');

  return getManualCrunchApi(
    fake_crunch_profile,
    login_token,
    forcePointsRedemption,
    skipFirstYear,
    utmObj
  )
    .then(json => {
      dispatch(fakeCrunchDataSuccess(json));
    })
    .then(() => {
      localStorage.setItem('__crunch-status', 'crunched');

      dispatch({ type: 'FAKE_PREFETCH_SFY_STARTED' });
      dispatch(__fakePrefetchSkipFirstYear(fake_crunch_profile, login_token, false, true, utmObj));
    })
    .catch(error => {
      dispatch(fakeCrunchDataFailure(error));

      // if (error.detail === 'Invalid token.') {
      //   localStorage.setItem('authState', null);
      // }
    });
};

// Prefetch functions
// forcePointsRedemption
const __fakePrefetchForcePointsRedemptionSuccess = ({ auth_token, crunch_data }) => {
  return {
    auth_token: auth_token,
    crunch_data: {
      by_card: crunch_data.by_card,
      created: crunch_data.created
    },
    type: 'FAKE_PREFETCH_FPR_SUCCESS'
  };
};

const __fakePrefetchForcePointsRedemptionFailure = error => ({
  type: 'FAKE_PREFETCH_FPR_FAILURE'
});

export const __fakePrefetchForcePointsRedemption = (
  fake_crunch_profile,
  login_token,
  forcePointsRedemption,
  skipFirstYear,
  utmObj
) => {
  return dispatch =>
    getManualCrunchApi(
      fake_crunch_profile,
      login_token,
      forcePointsRedemption,
      skipFirstYear,
      utmObj
    )
      .then(json => {
        dispatch(__fakePrefetchForcePointsRedemptionSuccess(json));
      })
      .then(() => {
        dispatch({ type: 'FAKE_PREFETCH_FPR_AND_SFY_STARTED' });
        dispatch(__fakePrefetchFPRandSFY(fake_crunch_profile, login_token, true, true, utmObj));
      })
      .catch(error => dispatch(__fakePrefetchForcePointsRedemptionFailure(error)));
};
// __fakePrefetchForcePointsRedemptionSuccess
// __fakePrefetchForcePointsRedemptionFailure

// skipFirstYear
const __fakePrefetchSFYSuccess = ({ auth_token, crunch_data }) => {
  return {
    auth_token: auth_token,
    crunch_data: {
      by_card: crunch_data.by_card,
      created: crunch_data.created
    },
    type: 'FAKE_PREFETCH_SFY_SUCCESS'
  };
};

const __fakePrefetchSFYFailure = error => ({
  type: 'FAKE_PREFETCH_SFY_FAILURE'
});

export const __fakePrefetchSkipFirstYear = (
  fake_crunch_profile,
  login_token,
  forcePointsRedemption,
  skipFirstYear,
  utmObj
) => {
  return dispatch =>
    getManualCrunchApi(
      fake_crunch_profile,
      login_token,
      forcePointsRedemption,
      skipFirstYear,
      utmObj
    )
      .then(json => dispatch(__fakePrefetchSFYSuccess(json)))
      .then(() => {
        dispatch({ type: 'FAKE_PREFETCH_FPR_STARTED' });
        dispatch(
          __fakePrefetchForcePointsRedemption(fake_crunch_profile, login_token, true, false, utmObj)
        );
      })
      .catch(error => dispatch(__fakePrefetchSFYFailure(error)));
};

// forcePointsRedemption and skipFirstYear
const __fakePrefetchFPRandSFYSuccess = ({ auth_token, crunch_data }) => {
  return {
    auth_token: auth_token,
    crunch_data: {
      by_card: crunch_data.by_card,
      created: crunch_data.created
    },
    type: 'FAKE_PREFETCH_FPR_AND_SFY_SUCCESS'
  };
};

const __fakePrefetchFPRandSFYFailure = error => ({
  type: 'FAKE_PREFETCH_FPR_AND_SFY_FAILURE'
});

export const __fakePrefetchFPRandSFY = (
  fake_crunch_profile,
  login_token,
  forcePointsRedemption,
  skipFirstYear,
  utmObj
) => {
  return dispatch =>
    getManualCrunchApi(
      fake_crunch_profile,
      login_token,
      forcePointsRedemption,
      skipFirstYear,
      utmObj
    )
      .then(json => dispatch(__fakePrefetchFPRandSFYSuccess(json)))
      .catch(error => dispatch(__fakePrefetchFPRandSFYFailure(error)));
};

// let url = document.location.protocol + "//" + document.location.host + "/api/"
// if (process.env.NODE_ENV === "development") {
//     url = "http://127.0.0.1:8000/"
// }

// export const fetchFakeCrunchData = () => {
//     return (dispatch, getState) => {
//         const { root } = getState()
//         const { fake_crunch_profile, login_token } = root
//         let headers = {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         }

//         if (login_token){
//             headers['Authorization'] = 'Token ' + login_token
//         }

//         fetch(`${url}api/fake_crunch/`, {
//             method: 'POST',
//             headers: headers,
//             body: JSON.stringify({ weights: fake_crunch_profile }),
//         })
//         .then(response => {
//             if (!response.ok) {
//                 throw Error(response.statusText)
//             } else {
//                 return response.json()
//             }
//         })
//         .then(json => {
//             dispatch(fakeCrunchDataSuccess(json))
//         })
//         .catch(error => {
//             dispatch(fakeCrunchDataFailure(error))
//         })
//     }
// }
