import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import cx from "classnames"

import "./button.scss"

function Button({
    ariaLabel,
    block,
    children,
    className,
    disabled,
    href,
    onClick,
    size,
    to,
    type,
    variant,
}) {
    const classes = cx(
        { [`a-button`]: !to && variant !== "link" },
        { [`a-button--${variant}`]: !!variant },
        { [`a-button--${size}-size`]: !!size },
        { [`a-button--block`]: !!block },
        { [`a-button--disabled`]: disabled },
        { [`a-link`]: !!to },
        className,
    )

    const handleClick = _ => {
        if (href) window.open(href, '_blank')
        onClick()
    }

    if (href) {
        return (
            <a
                aria-label={ariaLabel}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        )
    }

    if (to) {
        return (
            <Link
                aria-label={ariaLabel}
                className={classes}
                to={to}
            >
                {children}
            </Link>
        )
    }

    return (
        <button
            aria-label={ariaLabel}
            className={classes}
            onClick={handleClick}
            type={type}
        >
            {children}
        </button>
    )
}

export default Button

Button.defaultProps = {
    disabled: false,
    target: "_blank",
    type: "button",
    onClick: () => {}
}

Button.propTypes = {
    ariaLabel: PropTypes.string,
    bgColor: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    size: PropTypes.oneOf([
        "sm",
        "md",
        "lg",
        "xlg",
    ]),
    to: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.oneOf([
        "primary",
        "invert-primary",
        "outline-primary",
        "secondary",
        "info",
        "warning",
        "dark",
        "outline-dark",
        "ghost-primary",
        "link",
    ]),
    onClick: PropTypes.func,
}
