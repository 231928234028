import React from "react"
import { Typography } from "../../../atoms"
import { List } from "../../../molecules"
import { Paper } from "../../../organisms"

export function AdditionalBenefits({ additionalBenefits }) {
    if (additionalBenefits.length && !additionalBenefits[0].text) return null

    return (
        <Paper className="m-additional-benefits" bgLight>
            <Typography
                className="--mb-1"
                component="h4"
                variant="overline"
            >
                Additional Benefits
            </Typography>
            <List variant="bullet">
                {additionalBenefits.map(({ text }) => <li key={text}>{text}</li>)}
            </List>
        </Paper>
    )
}

export function CardBenefits({ cardBenefits }) {
    if (cardBenefits.length && !cardBenefits[0].title) return null

    return cardBenefits.map(({ description, icon, title, }) =>
        <div className="m-card-benefit" key={title}>
            <Typography
                className="--mb-1"
                component="h4"
                variant="overline"
            >
                {title} 
            </Typography>
            {description && <p>{description}</p>}
        </div>
    )
}
