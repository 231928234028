import React from "react"
import cx from "classnames"

import ArrowIcon from "../../../assets/images/icons/icon-arrow.svg";

function PanelHeader(props) {
    const classes = cx("o-panel__header")

    return (
        <div className={classes}>
            {props.children}
            {props.collapsable && (
                <div className="arrow" onClick={props.onClick}>
                    <img src={ArrowIcon} alt="toggle" />
                </div>
            )}
        </div>
    )
}

PanelHeader.displayName = "Panel Header"

export default PanelHeader