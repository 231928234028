import React, { useEffect, useRef, useState } from "react"

function DropdownMenu(props) {
    const [ height, setHeight ] = useState(0)
    const element = useRef(null)
    const style = {
        height: props.collapsed ? height : 0,
        maxHeight: props.maxHeight ? props.maxHeight : "inherit",
    }

    useEffect(() => {
        const height = element.current.offsetHeight
        setHeight(height)
    }, [element])

    return (
        <div className="o-dropdown__menu" style={style}>
            <div className="" ref={element}>
                {props.children}
            </div>
        </div>
    )
}

export default DropdownMenu

DropdownMenu.displayName = "Dropdown Menu"
