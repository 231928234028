import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./hr.scss"

function Hr(props) {
    const classes = cx(
        "a-hr",
        { [`a-hr--${props.size}-size`]: !!props.size },
        props.className,
    )

    return (
        <hr className={classes} />
    )
}

export default Hr

Hr.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(["sm", "md", "lg"]),
}
