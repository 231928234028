import React from "react"
import { Link } from "react-router-dom"
import { Button, Hr, Input, LazyImage, Typography } from "../../atoms"
import { FormRow } from "../../molecules"
import { Container } from "../../templates"

import AppLogo from "../../../assets/images/app-logo-Copy.png"

import "./footer.scss"

const Logo = () => (
    <Link className="o-footer__logo" to="/">
        <LazyImage srcPreload={AppLogo} alt="CardCruncher" />
    </Link>
)

const Copy = () => (
    <article className="o-footer__copy">
        <Typography component="h4" variant="overline">What is CardCruncher?</Typography>
        <p>CardCruncher instantly sorts and categorizes all of your spending from the previous year and compares it to over 150 credit card rewards programs.</p>
    </article>
)

const FirstMenu = () => (
    <article className="o-footer__first-menu">
        <Typography component="h4" variant="overline">Learn</Typography>
        <ul className="o-footer__menu">
            <li><a href="/reviews">Reviews</a></li>
            <li><a href="/advice">Advice</a></li>
            <li><a href="/best-credit-cards">Best Credit Cards</a></li>
        </ul>
    </article>
)

const SecondMenu = () => (
    <article className="o-footer__second-menu">
        <Typography component="h4" variant="overline">About</Typography>
        <ul className="o-footer__menu">
            <li><Link to="/security">Security</Link></li>
            <li><a href="/#how-it-works">How it works</a></li>
            <li><a href="mailto:support@cardcruncher.com">Contact us</a></li>
        </ul>
    </article>
)

const Copyright = () => (
    <p className="o-footer__copyright">&copy; CardCruncher Inc.</p>
)

const CopyrightMenu = () => (
    <ul className="o-footer__copyright-menu">
        <li><Link to="/terms">Terms</Link></li>
        <li><Link to="/privacy">Privacy</Link></li>
    </ul>
)

const Newsletter = ({
    email,
    onFormSubmit,
    onInputChange
}) => (
    <article className="o-footer__newsletter">
        <Typography component="h4" variant="overline">Newsletter</Typography>
        <p>For weekly recommendations.</p>
        <form onSubmit={onFormSubmit}>
            <FormRow>
                <Input
                    aria-label="Newsletter input"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={event => onInputChange(event)}
                    required
                />
                <Button ariaLabel="Newsletter button" variant="primary" type="submit">Join</Button>
            </FormRow>
        </form>
    </article>
)

const AdvertiserDisclosure = () => (
    <p className="o-footer__advertiser-disclosure">
        <b>Advertiser disclosure:</b>{" "}
        CardCruncher has partnered with CardRatings for our coverage of credit card products.
        CardCruncher and CardRatings may receive a commission from card issuers.
        This is how we offer CardCruncher for free to our users! Commissions do not affect or prioritize placement within our results and not all cards displayed earn us a commission.
        This content is not provided by any bank advertiser. Opinions expressed here are author's alone, not those of the issuer, and have not been reviewed, approved or otherwise endorsed by the issuer.
    </p>
)

function Footer(props) {
    return (
        <footer className="o-footer">
            <Container className="o-footer__container">
                <Logo />
                <Copy />
                <FirstMenu />
                <SecondMenu />
                <Newsletter {...props} />
                <Hr />
                <AdvertiserDisclosure />
                <Copyright />
                <CopyrightMenu />
            </Container>
        </footer>
    )
}

export default Footer
