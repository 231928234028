import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./sidebar.scss"

function Sidebar(props) {
    const classes = cx(
        props.className,
        "o-sidebar",
        { [`o-sidebar--${props.size}-size`]: !!props.size },
    )

    return (
        <aside className={classes}>
            {props.children}
        </aside>
    )
}

export default Sidebar

Sidebar.defaultProps = {
    size: "def",
}

Sidebar.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf([
        "sm",
        "def",
    ]),
}
