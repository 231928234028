import React from "react"
import cx from "classnames"

import ArrowIcon from "../../../assets/images/icons/icon-arrow.svg";

function DropdownToggle(props) {
    const classes = cx("o-dropdown__toggle")

    return (
        <div className={classes} onClick={props.onClick}>
            {props.children}
            <div className="arrow">
                <img src={ArrowIcon} alt="toggle" />
            </div>
        </div>
    )
}

export default DropdownToggle

DropdownToggle.displayName = "Dropdown Toggle"