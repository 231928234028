import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./tag.scss";

function Tag(props) {
    const classes = cx(
        "a-tag",
        { "--no-bg": !!props.noBg },
    )

    return (
        <div className={classes}>
            {props.children}
            {props.name}
            {props.value && <div
                className="a-tag__cta"
                onClick={event => props.onClick(event, props.value)}
            />}
        </div>
    )
}

export default Tag;

Tag.defaultProps = {
    onClick: () => {}
}

Tag.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onClick: PropTypes.func,
    noBg: PropTypes.bool,
}
