import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import cx from "classnames"
import { LazyImage, Typography } from "../../atoms"

import "./featured.scss"

function Featured(props) {
    const classes = cx(
        `o-featured`,
        props.className,
    )

    return (
        <article className={classes}>
            <Link to="/" />
            <LazyImage className="o-featured__image" srcPreload={props.image} alt={props.full_name} />
            <Typography className="o-featured__title" component="h4" variant="body2">{props.title}</Typography>
        </article>
    )
}

export default Featured

Featured.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}
