import React from "react"
// import PropTypes from "prop-types"
// import Collapse from "@material-ui/core/Collapse"
import cx from "classnames"
import { isElement } from "../../../utilities/utils"
import PanelHeader from "./panel-header"
import PanelBody from "./panel-body"

import "./panel.scss"

function Panel(props) {
    const children = React.Children.toArray(props.children)
    const classes = cx(
        props.className,
        "o-panel",
        { [`--${props.variant}`]: !!props.variant },
        { [`--only-body`]: !!props.onlyBody },
        { [`--gradient-${props.gradient}`]: !!props.gradient },
    )
    const header = []
    const body = []

    children.forEach(child => {
        if (child && isElement(child, PanelHeader.displayName)) {
            const state = {}

            header.push(React.cloneElement(child, {...state}))
        }

        if (child && isElement(child, PanelBody.displayName)) {
            const state = {}

            body.push(React.cloneElement(child, {...state}))
        }
    })

    return (
        <div className={classes}>
            {header && header}
            {body && body}
        </div>
    )
}

export default Panel

Panel.Header = PanelHeader
Panel.Body = PanelBody