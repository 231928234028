const initialState = {
    isFetching: false,
    isLoaded: false,
    isOpen: false,
    payload: null,
}

export function plaidReducer(state = initialState, action) {
    switch (action.type) {
        case "PLAID_LOADED":
            return {
                ...state,
                isLoaded: true
            }
        case "OPEN_PLAID":
            return {
                ...state,
                isOpen: true
            }
        case "CLOSE_PLAID":
            return {
                ...state,
                isOpen: false
            }
        case "REQUEST_TOKEN_EXCHANGE":
            return {
                ...state,
                isFetching: true,
            }
        case "RECEIVE_TOKEN_EXCHANGE":
            return {
                ...state,
                isFetching: false,
                payload: action.payload
            }
        default:
            return state
    }
}
