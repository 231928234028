import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import "./list.scss"

export default function List(props) {
    const classes = cx(
        `m-list`,
        { [`m-${props.variant}-list`]: !!props.variant },
        props.className,
    )

    return <ul className={classes}>{props.children}</ul>
}

List.propTypes = {
    variant: PropTypes.oneOf([
        "decimal",
        "bullet",
    ]),
}
