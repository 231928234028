import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./checkbox.scss"

function Checkbox(props) {
    const classes = cx("a-checkbox")

    return (
        <label className={classes}>
            <input
                name={props.value}
                type="checkbox"
                checked={props.checked}
                onChange={event => props.onChange(event, {
                    name: props.name,
                    value: props.value
                })}
            />
            <span className="a-checkbox__control" />
            <span className="a-checkbox__label">{props.name}</span>
        </label>
    )
}

export default Checkbox

Checkbox.defaultProps = {
    checked: false,
    onChange: () => {}
}

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}
