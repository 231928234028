import React from "react"
import PropTypes from "prop-types"

import IconStarActive from "../../../assets/images/icons/icon-star-active.svg"
import IconStarInactive from "../../../assets/images/icons/icon-star-inactive.svg"
import IconStarHalf from "../../../assets/images/icons/icon-star-half.svg"

import "./stars.scss"

function getRatingStar(value, index) {
    const diff = parseFloat(value) - index

    if (diff >= 0) {
        return IconStarActive
    } else if (diff > -1 && diff < 0) {
        return IconStarHalf
    } else if (diff <= -1) {
        return IconStarInactive
    }
}

function Stars(props) {
    return (
        <div className="a-stars">
            <img src={getRatingStar(props.score, 1)} alt="" />
            <img src={getRatingStar(props.score, 2)} alt="" />
            <img src={getRatingStar(props.score, 3)} alt="" />
            <img src={getRatingStar(props.score, 4)} alt="" />
            <img src={getRatingStar(props.score, 5)} alt="" />
        </div>
    )
}

export default Stars

Stars.propTypes = {
    score: PropTypes.string.isRequired,
}
