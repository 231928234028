import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import DropdownToggle from "./dropdown-toggle"
import DropdownMenu from "./dropdown-menu"
import { isElement } from "../../../utilities/utils"

import "./dropdown.scss"

function Dropdown(props) {
    const [collapsed, setCollapsed] = useState(false);
    const children = React.Children.toArray(props.children)
    const classes = cx(
        props.className,
        "o-dropdown",
        { "is-collapsed": collapsed },
        { [`--${props.variant}`]: !!props.variant },
    )
    const toggle = []
    const menu = []

    children.forEach(child => {
        const defaultState = {
            collapsed: collapsed,
        }

        if (isElement(child, DropdownToggle.displayName)) {
            const state = {
                ...defaultState,
                onClick: () => setCollapsed(prevState => !prevState)
            }

            toggle.push(React.cloneElement(child, {...state}))
        }

        if (isElement(child, DropdownMenu.displayName)) {
            const state = {
                ...defaultState,
                maxHeight: props.maxHeight
            }

            menu.push(React.cloneElement(child, {...state}))
        }
    })

    return (
        <div className={classes}>
            {toggle && toggle}
            {menu && menu}
        </div>
    )
}

export default Dropdown

Dropdown.Toggle = DropdownToggle
Dropdown.Menu = DropdownMenu

Dropdown.propTypes = {
    collapsed: PropTypes.bool,
    maxHeight: PropTypes.oneOf(["number", "string"])
}
