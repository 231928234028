import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './lazy-image.scss';

let imageLoaded = false;

const LazyImage = ({ alt, children, className, onClick, placeholder, src, type }) => {
  const [srcState, setSrcState] = useState(placeholder | null);

  const isBgImage = type === 'background';
  const rootClass = 'a-lazy-image';
  const classes = cx(className, rootClass, { '--bg': isBgImage });
  const fadeInClass = srcState ? `${rootClass}--fade-in` : '';

  const handleClick = event => {
    if (onClick) onClick(event);
  };

  const handleImage = () => {
    if (!imageLoaded) {
      const image = new Image();

      image.src = src;
      image.onload = () => setSrcState(src);

      setTimeout(() => {
        imageLoaded = true;
      }, 300);
    } else {
      setSrcState(src);
    }
  };

  useEffect(() => {
    if (src !== srcState) handleImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  if (isBgImage) {
    const style = {
      backgroundImage: `url(${srcState})`
    };

    return (
      <div className={`${classes} ${fadeInClass}`} style={style}>
        {children}
      </div>
    );
  }

  return (
    <div className={classes} onClick={handleClick}>
      <img className={`${rootClass}--loaded ${fadeInClass}`} alt={alt} src={srcState} />
    </div>
  );
};

export default LazyImage;

LazyImage.defaultProps = {
  type: 'image'
};

LazyImage.propTypes = {
  alt: PropTypes.string,
  placeholder: PropTypes.string,
  src: PropTypes.string
};
