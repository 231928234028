import { push } from 'react-router-redux';
import { isSnap } from '../../config/api-services-config';

let url = document.location.protocol + '//' + document.location.host + '/api/';
if (process.env.NODE_ENV === 'development') {
  url = 'http://127.0.0.1:8000/';
}

export const logoutSuccess = () => ({
  type: 'LOGOUT_SUCCESS'
});

export const logout = () => (dispatch, getState) => {
  const login_token = getState().root.login_token;

  fetch(`${url}api/logout/`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + login_token
    }
  })
    .then(() => {
      dispatch(push('/'));
      dispatch(logoutSuccess());
    })
    .catch(error => {
      console.error(error);
    });
};

// TO DO: to be removed
export const fetchCrunchDataNotReady = () => ({
  type: 'CRUNCH_NOT_READY'
});

// TO DO: to be removed
export const fetchCrunchDataSuccess = data => ({
  type: 'CRUNCH_SUCCESS',
  crunch_data: {
    by_card: data.by_card,
    created: data.created
  }
});

export const prefetchFPRSuccess = data => ({
  type: 'PREFETCH_FPR_SUCCESS',
  crunch_data: {
    by_card: data.by_card,
    created: data.created
  }
});

export const prefetchSFISuccess = data => ({
  type: 'PREFETCH_SFY_SUCCESS',
  crunch_data: {
    by_card: data.by_card,
    created: data.created
  }
});

export const prefetchFPRandSFISuccess = data => ({
  type: 'PREFETCH_FPR_AND_SFY_SUCCESS',
  crunch_data: {
    by_card: data.by_card,
    created: data.created
  }
});

// TO DO: to be removed
export const fetchCrunchDataFailure = error => ({
  type: 'CRUNCH_FAILURE'
});

//### START ###//
// TO DO: To be removed
export const fetchCrunchData = utmObj => {
  // const startTime = performance.now()

  return (dispatch, getState) => {
    const login_token = getState().root.login_token;
    localStorage.setItem('__crunch-status', 'crunching');

    if (getState().root.crunch_data) {
      // Break the timeout loop after the data has arrived
      return;
    }

    // let callUrl = `${url}api/crunch/`
    // if (forcePointsRedemption !== undefined && skipFirstYear !== undefined) {
    // callUrl = `${url}api/crunch/?forcePointsRedemption=${forcePointsRedemption}&skipFirstYear=${skipFirstYear}`
    // } else {
    const utm = `utm_source=${utmObj.utm_source}&utm_medium=${utmObj.utm_medium}`;
    const callUrl = `${url}api/crunch/?forcePointsRedemption=false&skipFirstYear=false&${utm}`;
    // }

    fetch(callUrl, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Token ' + login_token
      }
    })
      .then(response => {
        if (response.status === 202) {
          dispatch(fetchCrunchDataNotReady());
          setTimeout(() => dispatch(fetchCrunchData(login_token)), 2000);
          // Break the promise chain
          let error = new Error('not_ready');
          error.code = 202;
          throw error;
        } else {
          return response.json();
        }
      })
      .then(json => {
        localStorage.setItem('__crunch-status', 'crunched');
        // const endTime = performance.now()
        // const duration = ((endTime - startTime)/1000.0).toFixed(2)
        // dispatch(logEvent(
        //     'fetch-crunch-data-success-duration',
        //     'fetch-crunch-data-success-duration seconds=' + duration,
        // ))
        dispatch(fetchCrunchDataSuccess(json));
      })
      .then(() => {
        // console.log("PREFETCH_SFY_STARTED")
        dispatch({ type: 'PREFETCH_SFY_STARTED' });
        dispatch(__prefetchSkipFirstYear(false, true));
      })
      .catch(error => {
        if (error.code !== 202) {
          // const endTime = performance.now()
          // const duration = ((endTime - startTime)/1000.0).toFixed(2)
          // dispatch(logEvent(
          //     'fetch-crunch-data-failure-duration',
          //     'fetch-crunch-data-failure-duration seconds=' + duration,
          // ))

          console.error(error);
          dispatch(fetchCrunchDataFailure(error));
        }
      });
  };
};
//### END ###//

export const __prefetchFPRandSFY = (forcePointsRedemption, skipFirstYear) => {
  return (dispatch, getState) => {
    // console.log("__prefetchFPRandSFY")
    const login_token = getState().root.login_token;

    // if (getState().root.crunch_prefetch_fpr_and_sfy_crunch_data) {
    //     // Break the timeout loop after the data has arrived
    //     return
    // }

    let callUrl = `${url}api/crunch/`;
    if (forcePointsRedemption !== undefined && skipFirstYear !== undefined) {
      callUrl = `${url}api/crunch/?forcePointsRedemption=${forcePointsRedemption}&skipFirstYear=${skipFirstYear}`;
    } else {
      callUrl = `${url}api/crunch/?forcePointsRedemption=false&skipFirstYear=false`;
    }

    fetch(callUrl, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Token ' + login_token
      }
    })
      .then(response => {
        // if (response.status === 202) {
        //     setTimeout(() => dispatch(__prefetchFPRandSFY(forcePointsRedemption, skipFirstYear)), 2000)
        //     // Break the promise chain
        //     let error = new Error("not_ready")
        //     error.code = 202
        //     throw error
        // } else {
        //     return response.json()
        // }
        return response.json();
      })
      .then(json => dispatch(prefetchFPRandSFISuccess(json)))
      .catch(error => {
        if (error.code !== 202) dispatch(fetchCrunchDataFailure(error));
      });
  };
};

export const __prefetchForcePointsRedemption = (forcePointsRedemption, skipFirstYear) => {
  return (dispatch, getState) => {
    // console.log("__prefetchForcePointsRedemption")
    const login_token = getState().root.login_token;

    // if (getState().root.crunch_prefetch_fpr_crunch_data) {
    //     // Break the timeout loop after the data has arrived
    //     return
    // }
    let callUrl = `${url}api/crunch/`;
    if (forcePointsRedemption !== undefined && skipFirstYear !== undefined) {
      callUrl = `${url}api/crunch/?forcePointsRedemption=${forcePointsRedemption}&skipFirstYear=${skipFirstYear}`;
    } else {
      callUrl = `${url}api/crunch/?forcePointsRedemption=false&skipFirstYear=false`;
    }

    fetch(callUrl, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Token ' + login_token
      }
    })
      .then(response => {
        // if (response.status === 202) {
        //     setTimeout(() => dispatch(__prefetchForcePointsRedemption(forcePointsRedemption, skipFirstYear)), 2000)
        //     // Break the promise chain
        //     let error = new Error("not_ready")
        //     error.code = 202
        //     throw error
        // } else {
        //     return response.json()
        // }
        return response.json();
      })
      .then(json => dispatch(prefetchFPRSuccess(json)))
      .then(() => {
        // console.log("PREFETCH_FPR_AND_SFY_STARTED")
        dispatch({ type: 'PREFETCH_FPR_AND_SFY_STARTED' });
        dispatch(__prefetchFPRandSFY(true, true));
      })
      .catch(error => {
        if (error.code !== 202) dispatch(fetchCrunchDataFailure(error));
      });
  };
};

export const __prefetchSkipFirstYear = (forcePointsRedemption, skipFirstYear) => (
  dispatch,
  getState
) => {
  const login_token = getState().root.login_token;

  // if (getState().root.crunch_prefetch_sfy_crunch_data) {
  //     // Break the timeout loop after the data has arrived
  //     return
  // }

  let callUrl = `${url}api/crunch/`;
  if (forcePointsRedemption !== undefined && skipFirstYear !== undefined) {
    callUrl = `${url}api/crunch/?forcePointsRedemption=${forcePointsRedemption}&skipFirstYear=${skipFirstYear}`;
  } else {
    callUrl = `${url}api/crunch/?forcePointsRedemption=false&skipFirstYear=false`;
  }

  fetch(callUrl, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Token ' + login_token
    }
  })
    .then(response => {
      // if (response.status === 202) {
      //     setTimeout(() => dispatch(__prefetchSkipFirstYear(forcePointsRedemption, skipFirstYear)), 2000)
      //     // Break the promise chain
      //     let error = new Error("not_ready")
      //     error.code = 202
      //     throw error
      // } else {
      //     return response.json()
      // }
      return response.json();
    })
    .then(json => dispatch(prefetchSFISuccess(json)))
    .then(() => {
      dispatch({ type: 'PREFETCH_FPR_STARTED' });
      dispatch(__prefetchForcePointsRedemption(true, false));
    })
    .catch(error => {
      if (error.code !== 202) dispatch(fetchCrunchDataFailure(error));
    });
};

export const logEvent = (eventType, eventLabel, eventValue) => {
  if (isSnap)
    return {
      type: 'NO_EVENT'
    };

  return {
    type: 'LOG_EVENT',
    eventType,
    eventLabel,
    eventValue
  };
};
