import React from "react"
import { Typography } from "../../../atoms"
import { Paper } from "../../../organisms"

const Proto = ({ title, value }) => (
    <Paper className="m-fees" bgLight>
        <Typography
            className="--mb-1"
            component="h4"
            variant="overline"
        >
            {title}
        </Typography>
        <p>{value}</p>
    </Paper>
)

export function Fees({ APRDescription, feeDescription, introAPR, regularAPR }) {
    if (APRDescription && feeDescription && introAPR && regularAPR) {
        return null
    }

    return (
        <div className="o-fees">
            {APRDescription && <Proto title="Apr" value={APRDescription} />}
            {introAPR && <Proto title="Intro Apr" value={introAPR} />}
            {regularAPR && <Proto title="Regular Apr" value={regularAPR} />}
            {feeDescription && <Proto title="Fees" value={feeDescription} />}
        </div>
    )
}
