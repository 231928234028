import actions from './';
import { getCardsListApi } from '../../api';

export const getCardsList = () => dispatch => {
  dispatch({ type: actions.REQUEST_CARDS });

  return getCardsListApi()
    .then(payload => {
      dispatch({
        type: actions.RECEIVE_CARDS,
        payload
      });
    })
    .catch(error => {
      dispatch({
        type: actions.RECEIVE_CARDS_ERROR,
        error
      });
      throw error;
    });
};

export const exchangeData = payload => {
  return {
    type: 'EXCHANGE_DATA',
    payload
  };
};
