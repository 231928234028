import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import Checkbox from "../../atoms/checkbox"

import "./checkbox-list.scss"

function CheckboxList(props) {
    const classes = cx(
        "m-checkbox-list",
        `m-checkbox-list--${props.direction}`
    );

    const isSelected = value => props.selected.map(i => i.value ? i.value : i).indexOf(value) !== -1

    return (
        <div className={classes}>
            {props.items.map(item => {
                const isItemSelected = isSelected(item.value ? item.value : item);

                return (
                    <Checkbox
                        key={item.name ? item.name : item}
                        name={item.name ? item.name : item}
                        value={item.value ? item.value : item}
                        checked={isItemSelected}
                        onChange={props.onChange}
                    />
                )
            })}
        </div>
    )
}

export default CheckboxList;

CheckboxList.defaultProps = {
    direction: "vertical",
}

CheckboxList.propTypes = {
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    items: PropTypes.array.isRequired,
    selected: PropTypes.array,
    // items: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         name: PropTypes.string.isRequired,
    //         value: PropTypes.string,
    //     })
    // ),
    // selected: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         name: PropTypes.string.isRequired,
    //         value: PropTypes.string,
    //     })
    // ),
    onChange: PropTypes.func.isRequired,
}
