import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./lazy-image.scss"

class LazyImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            src: null,
        }
        // this.imagePreload = React.createRef()
    }

    componentDidMount() {
        // console.log("componentDidMount")
        const image = new Image()
        image.src = this.props.srcPreload

        // console.log("image.Before onload", image.onload)
        image.onload = (a) => {
            // console.log("image.onload", a.path, this.props.srcPreload)
            // console.log("image.onload ")
            if (this.state.src === null) {
                // console.log("image.onload inside")
                // console.log("this.state.src", this.props)
                this.setState({
                    src: this.props.srcPreload
                })
            }

            // if (this.imagePreload.current !== null) {
            //     const current = this.imagePreload.current
            //     if (this.props.type === "background") {
            //         current.setAttribute("style", `background-image: url(${this.props.srcPreload})`);
            //     } else {
            //         current.setAttribute("src", this.props.srcPreload)
            //         current.setAttribute("alt", this.props.alt)
            //     }
                
            //     current.classList.add('a-lazy-image--fade-in')
            //     this.props.onSuccess(true)
            // }
        }
    }

    render() {
        // console.log("LazyImage state", this.state)
        // console.log("LazyImage props", this.props)
        // console.log("LazyImage render state", this.state)
        // console.log("LazyImage render props", this.props)

        const classes = cx(
            this.props.className,
            "a-lazy-image",
            { [`--bg`]: this.props.type === "background" },
        )

        if (this.props.type === "background") {
            return (
                <div
                    className={`${classes} ${this.state.src ? "a-lazy-image--fade-in" : ""}`}
                    style={{backgroundImage: `url(${this.props.srcPreload})`}}
                >
                    {this.props.children}
                </div>
            )
        }

        return (
            <div className={classes} onClick={this.props.onClick}>
                <img
                    className={`a-lazy-image--loaded ${this.state.src ? "a-lazy-image--fade-in" : ""}`}
                    alt={this.props.alt}
                    src={this.state.src}
                />
            </div>
        )
    }
}

export default LazyImage

LazyImage.defaultProps = {
    type: "image",
    onSuccess: () => {},
    onClick: () => {}
}

LazyImage.propTypes = {
    alt: PropTypes.string,
    srcPreload: PropTypes.string.isRequired,   
}
