export const data = {
    landing: {
        title: "The best credit card for you?",
        text: "CardCruncher crunches your spending (and calculates a whole bunch of other data) to automatically find the best cards for you, and make sure you’re maximizing rewards."
    },
    feature: {
        1: {
            title: "Connect your cards",
            text: "First securely connect the accounts and cards you use to make everyday purchases. We use Plaid — an industry standard financial platform. No data is stored, our AI just crunches the numbers"
        },
        2: {
            title: "Crunch your spending history",
            text: "CardCruncher crunches all the data for you, analyzing up to two years of categorized transactions. Our automated tools calculate your Crunch Score and match your real spending to the most useful credit cards."
        },
        3: {
            title: "Optimize and apply",
            text: "See how much cash back you would’ve earned on hundreds of credit cards, how to maximize your card usage, and so much more — all based on your transaction history. Think of it as your credit card power tool."
        }
    },
    video: {
        title: "How we find your perfect card",
        text: "Most people spend days or even weeks researching and comparing many credit card reward programs. CardCruncher does all of the work in seconds.",
    },
    cards: {
        title: "We pick from over 150 credit cards",
        text: "CardCruncher instantly sorts and categorizes all of your spending from the previous year and compares it in to the rewards programs of over 150 credit cards."
    },
}
