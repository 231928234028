import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../../atoms';

import './highlight.scss';

function Highlight(props) {
  return (
    <div className={`m-highlight ${props.className}`}>
      <Typography component="h4" variant="overline">
        {props.title}
      </Typography>
      <Typography component="p" variant="body2" weight="500">
        {props.value}
      </Typography>
    </div>
  );
}

export default Highlight;

Highlight.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};
