export default {
  REQUEST_CARD_DETAILS: 'REQUEST_CARD_DETAILS',
  RECEIVE_CARD_DETAILS: 'RECEIVE_CARD_DETAILS',
  RESET_CARD_DETAILS: 'RESET_CARD_DETAILS',
  REQUEST_ENV_DATA: 'REQUEST_ENV_DATA',
  RECEIVE_ENV_DATA: 'RECEIVE_ENV_DATA',
  REQUEST_PAGE_CONTENT: 'REQUEST_PAGE_CONTENT',
  RECEIVE_PAGE_CONTENT: 'RECEIVE_PAGE_CONTENT',
  REQUEST_CARDS: 'REQUEST_CARDS',
  RECEIVE_CARDS: 'RECEIVE_CARDS',
  RECEIVE_CARDS_ERROR: 'RECEIVE_CARDS_ERROR',
  SEARCH_CARDS: 'SEARCH_CARDS',
  LOAD_CARDS: 'LOAD_CARDS',
  SEND_APPLY_FOR: 'SEND_APPLY_FOR'
};
