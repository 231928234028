import React from "react"
// import PropTypes from "prop-types"
import cx from "classnames"
import { Typography } from "../../atoms"
import Table from "../table"
import { getIcon } from "../../atoms/icon"
import { calculateTotalsFromComponents } from "../../../utilities/index"

import "./crunch-values-table.scss"

const COLORS = [ "#6293ad", "#80cde4", "#76e9ea" ]

const formatData = (value) => {
    let val = value;
    const a = value.toFixed(2);
    const b = a.toString().split(".")
    const c = b[0];
    const d = b[1];

    if (b[1]) {
        if (d === "00") {
            val = c;
        } else if (d[d.length - 1] === "0") {
            val = `${c}.${d[0]}`;
        } else if (d[0] === "0" && d[d.length - 1] !== "0") {
            val = `${c}.${d}`;
        } else {
            val = a;
        }
    }

    return val;
}

const ShortVersion = props => {
    const firstThree = props.transactionComponents.slice(0, 3)
    const lastFour = props.transactionComponents.slice(3)
    const { transactionCrunchValue } = calculateTotalsFromComponents(lastFour)

    return (
        <tbody>
            {firstThree.map((item, key) => (
                <tr key={item.name}>
                    <td>
                        <div className="--details">
                            {getIcon(item.name, COLORS[key])}
                            <Typography
                                accent="gray-5"
                                className="--uppercase"
                                variant="overline"
                            >
                                {item.name}
                            </Typography>
                        </div>
                    </td>
                    <td className="--text-right" style={{ color: COLORS[key] }}>${item.crunch_value.toFixed(0)}</td>
                </tr>
            ))}
            <tr>
                <td>
                    <div className="--details">
                        {getIcon("Other", "#1d4d66")}
                        <Typography
                            accent="gray-5"
                            className="--uppercase --other"
                            variant="overline"
                        >
                            +4 more categories
                        </Typography>
                    </div>
                </td>
                <td className="--text-right" style={{ color: "#1d4d66" }}>${transactionCrunchValue.toFixed(0)}</td>
            </tr>
        </tbody>
    )
}

const LongVersion = props => (
    <>
        <thead>
            <tr>
                <th>
                    <Typography className="--uppercase" variant="overline">Category</Typography>
                </th>
                <th>
                    <Typography className="--uppercase" variant="overline">Your spending</Typography>
                </th>
                <th>
                    <Typography className="--uppercase" variant="overline">Rate</Typography>
                </th>
                <th className="--text-right">
                    <Typography className="--uppercase" variant="overline">Total</Typography>
                </th>
            </tr>
        </thead>
        <tbody>
            {props.transactionComponents.map(item => (
                <tr key={item.name}>
                    <td>
                        <div className="--details">
                            {getIcon(item.name, "#8899ac")}
                            <Typography
                                accent="gray-5"
                                className="--uppercase"
                                variant="overline"
                            >
                                {item.name}
                            </Typography>
                        </div>
                    </td>
                    <td className="is-total-spending">${item.total_spending.toFixed(0)}</td>
                    <td className="is-cashback">
                        {item.cashback_factor !== item.min_cashback_factor
                            ? "up to" : null
                        }
                        {formatData(item.cashback_factor * 100)}%
                    </td>
                    <td className="--text-right" style={{ color: "#8899ac" }}>${item.crunch_value.toFixed(0)}</td>
                </tr>
            ))}
        </tbody>
    </>
)

function CrunchValuesTable(props) {
    const classes = cx(
        props.className,
        "o-crunch-values-table",
        { [`--short-version`]: !!props.shortVersion },
    )

    if (props.shortVersion) {
        return (
            <Table className={classes}>
                <ShortVersion transactionComponents={props.transactionComponents} />
            </Table>
        )
    }

    return (
        <Table className={classes}>
            <LongVersion transactionComponents={props.transactionComponents} />
        </Table>
    )
}

export default CrunchValuesTable

