import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import "./placeholder.scss"

export default function Placeholder(props) {
    const classes = cx(
        "m-placeholder",
        props.className,
    )

    return <div className={classes} />
}

Placeholder.defaultProps = {
    variant: "row"
}

Placeholder.propTypes = {
    variant: PropTypes.oneOf(["image", "row"]).isRequired
}
