import React from "react"
import Slider from "react-slick"
// import { Link } from "react-router-dom"
import { LazyImage, Typography } from "../../../atoms"
import { Rating } from "../../../molecules"
import { getCardRelativeURL } from "../../../../utilities/utils"

const Card = ({ full_name, id, image, slug, review_score, onClick, onTouch }) => (
    <article className="o-related-card" onClick={(onClick) => {}}>
        {/* <Link to={`/credit-cards/${id}`}> */}
        <a href={`/${slug}`}>
        <LazyImage className="o-related-card__image" srcPreload={image} />
        <Typography
            className="o-related-card__name"
            component="h3"
        >
            {full_name}
        </Typography>
        <Rating score={review_score} />
        </a>
        {/* </Link> */}
    </article>
)

export function RelatedCards({ cards, onRelatedCardClick }) {
    // const [before, setBefore] = useState(false)
    // const [after, setAfter] = useState(false)

    // const handleBefore = () => {
    //     setAfter(false)
    //     setBefore(true)
    // }

    // const handleAfter = () => {
    //     setBefore(false)
    //     setAfter(true)
    // }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        // slidesToScroll: 1,
        touchThreshold: 20,
        // edgeFriction: 0,
        variableWidth: true,
        swipe: true,
        touchMove: true,
        draggable: true,
        // beforeChange: () => handleBefore(),
        // afterChange: () => handleAfter()
    }

    return (
        <Slider {...settings}>
            {cards.map(card => {
                const slug = getCardRelativeURL(card.issuer, card.slug, card.id)
                return (
                    <Card
                        {...card}
                        slug={slug}
                        key={card.id}
                        onClick={() => onRelatedCardClick(card)}
                    />
                )
            })}
        </Slider>
    )
}
