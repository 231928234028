import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./grid.scss"

export function Container(props) {
    const classes = cx(
        props.className,
        `t-container`,
        { [`--lg`]: !!props.lg },
    )

    return (
        <div className={classes}>{props.children}</div>
    )
}

export function Content(props) {
    const classes = cx(
        `t-content`,
        { [`t-content--${props.padd}-padd`]: !!props.padd },
        props.className,
    )

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}

Content.defaultProps = {}

Content.propTypes = {
    className: PropTypes.string,
    padd: PropTypes.oneOf([
        "xs",
        "sm",
        "md",
        "lg",
        "xlg"
    ]),
}

export function Main(props) {
    return (
        <main className="t-main">{props.children}</main>
    )
}

function Col(props) {
    const classes = cx(
        "t-col",
        { [`--xs-${props.xs}`]: !!props.xs },
        { [`--sm-${props.sm}`]: !!props.sm },
        { [`--md-${props.md}`]: !!props.md },
        { [`--lg-${props.lg}`]: !!props.lg },
        props.className,
    )

    return <div className={classes}>{props.children}</div>
}

export function Row(props) {
    const classes = cx(
        "t-row",
        { "--sm": !!props.sm },
        { "--flex": !!props.flex },
        props.className,
    )

    return <div className={classes}>{props.children}</div>
}

Row.Col = Col
