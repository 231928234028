import actions from '../actions';
import { isSnap } from '../../config/api-services-config.js';

// const PAGINATION_OFFSET = 12;

const initialState = {
  isFetching: false,
  error: null,
  payload: null
  //   pagination: {
  //     visible: false,
  //     size: PAGINATION_OFFSET
  //   },
  //   search: {
  //     term: '',
  //     issuers: []
  //   }
};

// function searchByTerm(array, searchTerm) {
//     return array.filter(card => {
//         const { full_name, issuer, review_score } = card
//         const indexOfName = full_name.toLowerCase().indexOf(searchTerm)
//         const indexOfIssuer = issuer.toLowerCase().indexOf(searchTerm)
//         const indexOfRating = review_score.toLowerCase().indexOf(searchTerm)

//         if (indexOfName >= 0 || indexOfIssuer >= 0 || indexOfRating >= 0) {
//             return card
//         }

//         return false
//     })
//     // .sort((a, b) => (a.full_name > b.full_name) ? 1 : ((b.full_name > a.full_name) ? -1 : 0))
// }

export function cardsListReducer(state = initialState, action) {
  let results = null;

  switch (action.type) {
    case actions.REQUEST_CARDS:
      return {
        ...state,
        isFetching: true
      };
    case actions.RECEIVE_CARDS:
      const { payload } = action;
      const issuers = [];
      const paymentNetworks = [];

      if (isSnap) {
        results = payload;
      } else {
        results = payload;
        // results = payload.slice(0, state.pagination.size)
      }

      payload.forEach(card => {
        const { issuer, payment_network } = card;

        if (issuers.indexOf(issuer) < 0) {
          issuers.push(issuer);
        }

        if (payment_network.length && paymentNetworks.indexOf(payment_network) < 0) {
          paymentNetworks.push(payment_network);
        }

        // if (card.crunch_values === undefined) {
        //   card['crunch_values'] = null;
        // }
      });

      return {
        ...state,
        // defaultPayload: payload,
        // pagination: {
        //   ...state.pagination,
        //   visible: true
        // },
        issuers: issuers.sort(),
        isFetching: false,
        payload: results,
        paymentNetworks: paymentNetworks.sort()
      };
    case 'EXCHANGE_DATA':
      return {
        ...state,
        payload: action.payload
      };
    // case actions.LOAD_CARDS:
    //   const { defaultPayload, pagination } = state;
    //   const paginationSize = pagination.size + PAGINATION_OFFSET;
    //   const newPagination = {
    //     visible: paginationSize <= defaultPayload.length,
    //     size: paginationSize
    //   };
    //   results = defaultPayload.slice(0, paginationSize);
    //   return {
    //     ...state,
    //     pagination: newPagination,
    //     payload: results
    //   };
    // case actions.SEARCH_CARDS:
    //   console.log('state', state);
    //   const searchTerm = action.value;
    //   results = searchByTerm(state.defaultPayload, searchTerm.toLowerCase());

    //   return {
    //     ...state,
    //     search: {
    //       term: searchTerm,
    //       issuer: []
    //     },
    //     pagination: {
    //       ...state.pagination,
    //       visible: false
    //     },
    //     payload: results
    //   };
    case actions.RECEIVE_CARDS_ERROR:
      return {
        ...state,
        error: {
          stack: action.error.stack,
          message: action.error.message
        }
      };
    default:
      return state;
  }
}
