import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Container } from "../../templates"
import HeroContent from "./hero-content"
import HeroObject from "./hero-object"

import "./hero.scss"

export default function Hero(props) {
    const classes = cx(
        props.className,
        "o-hero",
        { [`--${props.size}`]: !!props.size },
        { [`--bg`]: !!props.bg },
    )

    return (
        <section className={classes}>
            <Container>{props.children}</Container>
        </section>
    )
}

Hero.Content = HeroContent
Hero.Object = HeroObject
Hero.propTypes = {
    size: PropTypes.oneOf(["xs", "md", "lg"])
}
