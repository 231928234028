import { postRequest, getRequest } from './request';
import {
  CARDS,
  CARD_DETAILS,
  CONNECT,
  CONTACT,
  CRUNCH_DATA,
  EMAIL,
  ENV_DATA,
  FACEBOOK,
  FAKE_CRUNCH,
  GOOGLE,
  LOGOUT,
  PAGE_CONTENT,
  URL,
  APPLY_FOR
} from '../config/api-services-config';

export async function contactApi(data) {
  return await postRequest(URL + CONTACT, data);
}

export async function emailApi(data, loginToken) {
  return await postRequest(URL + EMAIL, data, loginToken);
}

export async function facebookLoginApi(accessToken, loginToken) {
  return await getRequest(URL + FACEBOOK + accessToken, loginToken);
}

export async function getCardsListApi() {
  return await getRequest(URL + CARDS);
}

export async function getCardDetailsApi(id) {
  return await getRequest(URL + CARD_DETAILS + id + '/details/');
}

export async function getManualCrunchApi(
  spendings,
  loginToken,
  forcePointsRedemption,
  skipFirstYear,
  utmObj
) {
  const payload = {
    weights: spendings,
    utm_medium: utmObj.utm_medium,
    utm_source: utmObj.utm_source,
    utm_campaign: utmObj.utm_campaign
  };
  // const weights = spendings
  let url = URL + FAKE_CRUNCH;
  if (forcePointsRedemption !== undefined && skipFirstYear !== undefined) {
    url =
      URL +
      FAKE_CRUNCH +
      `?forcePointsRedemption=${forcePointsRedemption}&skipFirstYear=${skipFirstYear}`;
  } else {
    url = URL + FAKE_CRUNCH + `?forcePointsRedemption=false&skipFirstYear=false`;
  }

  return await postRequest(url, payload, loginToken);
}

export async function getEnvDataApi() {
  return await getRequest(URL + ENV_DATA);
}

// TODO: working?
export async function getPlaidCrunchApi(loginToken) {
  return await getRequest(URL + CRUNCH_DATA, loginToken);
}

export async function getTokenExchangeApi(public_token, metadata) {
  return await postRequest(URL + CONNECT, {
    plaid_public_token: public_token,
    metadata
  });
}

export async function googleLoginApi(accessToken, loginToken) {
  return await getRequest(URL + GOOGLE + accessToken, loginToken);
}

export async function logOutApi(loginToken) {
  return await getRequest(URL + LOGOUT, loginToken);
}

export async function getPageContentApi() {
  return await getRequest(URL + PAGE_CONTENT);
}

export async function sendApplyForApi(cardId, loginToken) {
  return await postRequest(URL + APPLY_FOR, cardId, loginToken);
}

// {plaidEnv && plaidEnv.public_key &&
//     (<PlaidLink
//         className={classes.plaidButton}
//         clientName="CardCruncher"
//         env={plaidEnv.env}
//         product={["transactions"]}
//         publicKey={plaidEnv.public_key}
//         webhook={plaidEnv.webhook_url}
//         apiVersion='v2'
//         onExit={this.handleOnExit}
//         onSuccess={this.getHandleOnSuccess(this)}>
//         connect account
//     </PlaidLink>)}
