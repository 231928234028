import React from "react"
import cx from "classnames"

import { Button, LazyImage, Meta, Typography } from "../../atoms"
import { Rating } from "../../molecules"

import "./article.scss"

function Article(props) {
    const classes = cx(
        props.className,
        "o-article",
    )

    return (
        <div className={classes}>
            <LazyImage
                className="o-article__image"
                srcPreload="https://cardcruncher.com/wp-content/uploads/2018/06/VerveCC-787x503.png"
                alt="Find the card that fits your spending and start earning."
            />
            <div className="o-article__content">
                <Typography className="o-article__title" component="h3" variant="subtitle2">
                    Find the card that fits your spending and start earning
                </Typography>
                <Meta>
                    <Meta.Item>
                        <Typography component="b" variant="badge">Brian Cherhoniak</Typography>
                    </Meta.Item>
                </Meta>
                <Rating score="4.6" text="Article Review" />
                <Typography component="p" variant="body2">
                    CardCruncher instantly sorts and categorizes all of your spending from the
                    previous year and comparesit to over 150 credit card rewards programs.
                </Typography>
                <Button to="/">Read Article</Button>
            </div>
        </div>
    )
}

export default Article
