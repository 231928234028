import actions from '../actions';
import { getEnvDataApi } from '../../api';

export const getEnvData = () => dispatch => {
  dispatch({ type: actions.REQUEST_ENV_DATA });

  return getEnvDataApi()
    .then(payload => {
      //   if (payload.stack) return null;
      //   console.log('here');
      dispatch({
        type: actions.RECEIVE_ENV_DATA,
        payload
      });
    })
    .catch(error => {
      console.log('error', error);
      throw error;
    });
};
