import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"
import { HashLink } from 'react-router-hash-link';
import { Button, LazyImage } from "../../atoms"

import AppLogo from "../../../assets/images/app-logo-Copy.png"

const Logo = ({ logoUrl }) => (
    <Link to="/" className="o-header__logo">
        <LazyImage srcPreload={logoUrl} alt="CardCruncher" />
    </Link>
)

const Burger = ({ onClick }) => (
    <div className="o-header__burger" onClick={onClick}>
        <span></span>
    </div>
)

const Menu = props => (
    <ul className="o-header__menu">
        <li>
            <Link
                to="/credit-cards"
                onClick={event => props.onClick(event, "Credit Cards")}
            >
                Credit Cards
            </Link>
        </li>
        <li>
            {props.userHasConnectedPlaid ? (
                <Link
                    to="/top-picks"
                    onClick={event => props.onClick(event, "View Top Picks")}
                >
                    View Top Picks
                </Link>
            ) : (
                <HashLink
                    to="/#how-it-works"
                    onClick={event => props.onClick(event, "How it works")}
                    smooth
                >
                    How it works
                </HashLink>
            )}
        </li>
    </ul>
)

const Mobile = props => (
    <div className="mobile">
        <nav className="mobile__nav --primary">
            {props.userHasConnectedPlaid ? (
                <Link
                    to="/top-picks"
                    onClick={event => props.onClick(event, "View Top Picks")}
                >
                    View Top Picks
                </Link>
            ) : (
                <HashLink
                    to="/#how-it-works"
                    onClick={event => props.onClick(event, "How it works")}
                    smooth
                >
                    How it works
                </HashLink>
            )}
            <Link to="/credit-cards" onClick={event => props.onClick(event, "Credit Cards")}>Credit Cards</Link>
            <a href="mailto:support@cardcruncher.com" onClick={event => props.onClick(event, "Contact us")}>Contact us</a>
        </nav>
        <nav className="mobile__nav --secondary">
            <a href="/reviews" onClick={event => props.onClick(event, "Reviews")}>Reviews</a>
            <a href="/advice" onClick={event => props.onClick(event, "Advice")}>Advice</a>
            <a href="/best-credit-cards" onClick={event => props.onClick(event, "Best Credit Cards")}>Best Credit Cards</a>
            <Link to="/security" onClick={event => props.onClick(event, "Security")}>Security</Link>
        </nav>
        <div className="mobile__footer">
            <p>CardCruncher Inc.</p>
            <nav>
                <Link to="/terms" onClick={event => props.onClick(event, "Terms")}>Terms</Link>
                <Link to="/privacy" onClick={event => props.onClick(event, "Privacy")}>Privacy</Link>
            </nav>
        </div>
    </div>
)

function Header(props) {
    const classes = cx(
        "o-header",
        { [`is-open`]: props.isOpen },
    )

    return (
        <>
            <header className={classes}>
                <div className="o-header__container">
                    <div className="o-header__brand">
                        <Logo logoUrl={AppLogo} />
                        <Burger onClick={props.onBurgerClick} />
                    </div>
                    <Menu
                        userHasConnectedPlaid={props.root.user_has_connected_plaid}
                        onClick={props.onMenuClick}
                    />
                    {props.root.user_has_connected_plaid ? (
                        <Button onClick={props.logout}>Log out</Button>
                    ) : (
                        <Button
                            className="o-header__cta"
                            variant="primary"
                            onClick={props.onStartCrunchingClick}
                        >
                            Start Crunching
                        </Button>
                    )}
                </div>
            </header>
            {props.isOpen &&
                <Mobile
                    userHasConnectedPlaid={props.root.user_has_connected_plaid}
                    onClick={props.onMobileMenuClick}
                />
            }
        </>
    )
}

export default Header
