import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getCardsList } from "../../../redux/actions/cards-list"
import { openPlaid } from "../../../redux/actions/__deprecated-plaid"
import { logout } from "../../../redux/actions/__deprecated-actions"
import { logEvent } from "../../../redux/actions/__deprecated-actions"
import Header from "./header"

import "./header.scss"

class HeaderContainer extends React.Component {
    state = {
        isOpen: false,
    }

    componentDidUpdate() {
        if (this.state.isOpen) {
            document.body.classList.add("is-menu-open")
        } else {
            document.body.classList.remove("is-menu-open")
        }
    }

    handleMenuClick = (_, name) => {
        this.props.logEvent(
            "header-menu-click",
            `header-menu-click - ${name}`,
        )
    }

    handleMobileMenuClick = (_, name) => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }), () => {
            this.props.logEvent(
                "header-mobile-menu-click",
                `header-mobile-menu-click - ${name}`,
            )
        })
    }

    handleBurgerClick = _event => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }), () => {
            this.props.logEvent(
                "header-mobile-burger-click",
                "header-mobile-burger-click"
            )
        })
    }

    handleStartCrunchingClick = async () => {
        this.props.logEvent(
            "header-start-crunching-click",
            "header-start-crunching-click",
        )

        openPlaid()
    }

    render() {
        return (
            <>
                <Header
                    {...this.props}
                    {...this.state}
                    onBurgerClick={this.handleBurgerClick}
                    onMenuClick={this.handleMenuClick}
                    onMobileMenuClick={this.handleMobileMenuClick}
                    onStartCrunchingClick={this.handleStartCrunchingClick}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        cardList: state.cardsList,
        root: state.root
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCardsList: () => dispatch(getCardsList()),
        showLoader: () => dispatch({ type: "REQUEST_FETCHING" }),
        hideLoader: () => dispatch({ type: "RECEIVE_FETCHING" }),
        openPlaid: () => dispatch(openPlaid()),
        logout: () => dispatch(logout()),
        logEvent: (eventType, eventLabel, eventValue) => {
            dispatch(logEvent(eventType, eventLabel, eventValue))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderContainer))
