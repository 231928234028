import React from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { Button, Input, Typography } from '../../atoms';
import { FormRow } from '../../molecules';
import { Modal } from '../../organisms';

const FbBtn = () => (
  <>
    <i className="fab fa-facebook-f" /> Connect with Facebook
  </>
);

const ConnectByEmail = ({ email, onChange, onSubmit }) => (
  <div className="by-email">
    <Typography component="h2" variant="h2">
      To see all of your recommended cards just connect with us first!
    </Typography>
    <Typography component="h3">Submit your email to view all credit cards</Typography>
    <form onSubmit={onSubmit}>
      <FormRow>
        <Input
          name="email"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={event => onChange(event)}
          required
        />
        <Button variant="primary" type="submit">
          Join
        </Button>
      </FormRow>
    </form>
  </div>
);

const ConnectBySocial = ({ onLoginWithFacebook, onLoginWithGoogle }) => (
  <div className="by-social">
    <Typography component="h3">or connect using social media</Typography>
    <div className="m-social-buttons">
      <FacebookLogin
        id="facebookButton"
        cssClass="a-button a-facebook-button"
        textButton={<FbBtn />}
        appId={'508774052849756'}
        fields="name, email"
        scope="public_profile, email"
        disableMobileRedirect={true}
        callback={onLoginWithFacebook}
      />
      <div className="a-separator" />
      <GoogleLogin
        className="a-button a-google-button"
        clientId="877037408140-66ht1rh58fsh4knh5d1dmr0ds64sreu6.apps.googleusercontent.com"
        buttonText="Connect with Google"
        onSuccess={onLoginWithGoogle}
        onFailure={error => console.log('GoogleLogin error', error)}
      />
    </div>
  </div>
);

export const EmailCollection = props => (
  <Modal
    className="o-social-modal is-email-collection"
    isOpen={props.isModalOpen}
    onRequestClose={props.onModalClose}
  >
    <Modal.Header>
      <ConnectByEmail email={props.email} onChange={props.onChange} onSubmit={props.onSubmit} />
      <ConnectBySocial
        onLoginWithFacebook={props.onLoginWithFacebook}
        onLoginWithGoogle={props.onLoginWithGoogle}
      />
    </Modal.Header>
  </Modal>
);
