import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import "./responsive-embed.scss"

function ResponsiveEmbed(props) {
    const classes = cx(
        "a-responsive-embed",
        { [`--${props.aspectRation}`]: !!props.aspectRation },
        props.className,
    )

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}

export default ResponsiveEmbed

ResponsiveEmbed.defaultProps = {
    aspectRation: "16by9"
}

ResponsiveEmbed.propTypes = {
    aspectRation: PropTypes.oneOf(["4by3", "16by9"])
}
