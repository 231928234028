export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';
export const isSnap = navigator.userAgent === 'ReactSnap';
let url = document.location.protocol + '//' + document.location.host + '/api/';

if (isDev) url = 'http://127.0.0.1:8000/';
if (isSnap) url = 'https://cardcruncher.com/api/';

export const URL = url;
export const CARDS = 'api/cards/';
export const CARD_DETAILS = 'api/card/';
export const CONNECT = 'api/connect/';
export const CONTACT = 'api/contact/';
export const CRUNCH_DATA = 'api/crunch/';
export const EMAIL = 'api/submit_email/';
export const ENV_DATA = 'plaid/env-data/';
export const FACEBOOK = 'api/login/facebook?access_token=';
export const FAKE_CRUNCH = 'api/fake_crunch/';
export const GOOGLE = 'api/login/google-oauth2?access_token=';
export const LOGOUT = 'api/logout/';
export const PAGE_CONTENT = 'api/page_content/';
export const APPLY_FOR = 'core/apply-for/';
