import React from "react"
import {
    EntertainmentIcon,
    GasStationsIcon,
    GroceriesIcon,
    OtherIcon,
    PharmacyIcon,
    RestaurantsIcon,
    TravelIcon
} from "./icons"

import "./icon.scss"

export function Icon(props) {
    const children = React.Children.toArray(props.children)
    const component = []

    children.forEach(child => {
        const state = {
            color: props.color
        }

        component.push(React.cloneElement(child, {...state}))
    })

    return component
}

export default Icon

Icon.Entertainment = EntertainmentIcon
Icon.GasStations = GasStationsIcon
Icon.Groceries = GroceriesIcon
Icon.Other = OtherIcon
Icon.Pharmacy = PharmacyIcon
Icon.Restaurants = RestaurantsIcon
Icon.Travel = TravelIcon

export function getIcon(name, color) {
    switch (name) {
        case "Entertainment":
            return <EntertainmentIcon color={color} />
        case "Gas Stations":
            return <GasStationsIcon color={color} />
        case "Groceries":
            return <GroceriesIcon color={color} />
        case "Other":
            return <OtherIcon color={color} />
        case "Pharmacy":
            return <PharmacyIcon color={color} />
        case "Restaurants/Dining":
            return <RestaurantsIcon color={color} />
        case "Travel":
            return <TravelIcon color={color} />
        default:
            return null
    }
}
