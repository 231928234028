import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { logEvent } from "../../../redux/actions/__deprecated-actions"
import { submitEmailForm } from "../../../redux/actions/__deprecated-email-form"
import Footer from "./footer"

import "./footer.scss"

class FooterContainer extends React.Component {
    state = {
        email: ""
    }

    handleInputChange = event => {
        this.setState({
            email: event.target.value
        })
    }

    handleFormSubmit = event => {
        event.preventDefault()

        this.props.logEvent(
            "click",
            "Top Picks",
            `Footer newsletter submit - ${this.state.email}`
        )
        // this.props.submitEmailForm(this.state.email)
    }

    render() {
        return (
            <>
                <Footer
                    {...this.props}
                    {...this.state}
                    onInputChange={this.handleInputChange}
                    onFormSubmit={this.handleFormSubmit}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user_has_connected_social: state.root.user_has_connected_social,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        submitEmailForm: (email) => dispatch(submitEmailForm(email)),
        logEvent: (eventType, eventLabel, eventValue) => {
            dispatch(logEvent(eventType, eventLabel, eventValue))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FooterContainer))
