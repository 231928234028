import React from 'react'

const GtmScript = () => {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.id = 'gtm-init-script';
        script.type = 'text/javascript';
        document.head.appendChild(script);
        script.innerText = `!function(e,t,a,n,g){e[n]=e[n]||[],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var m=t.getElementsByTagName(a)[0],r=t.createElement(a);r.async=!0,r.src="https://www.googletagmanager.com/gtm.js?id=GTM-TV9BKTH",m.parentNode.insertBefore(r,m)}(window,document,"script","dataLayer");`;
    }, []);

    return <></>
}

export default GtmScript;
