import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Stars, Typography } from "../../atoms"

import "./rating.scss"

function Rating(props) {
    const classes = cx(
        `m-rating`,
        props.className,
    )

    return (
        <div className={classes}>
            <Stars score={props.score} />
            <Typography accent="gray-4" variant="caption">{props.score}</Typography>
            <Typography accent="primary" variant="caption">{props.text}</Typography>
        </div>
    )
}

export default Rating

Rating.propTypes = {
    score: PropTypes.string.isRequired,
}
