import React from 'react';
import { Route } from 'react-router-dom';
import CardsContainer from './proto/container';

// const CardsContainer = React.lazy(() => import('./proto/container'));
export const route = (
  <Route
    exact
    render={props => <CardsContainer {...props} />}
    path="/credit-cards"
    key="credit-cards"
  />
);
