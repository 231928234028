import React from "react"
import { Typography } from "../../atoms"
import { Modal } from "../"

import "./advertiser-disclosure.scss"

export default function AdvertiserDisclosureModal(props) {
    return (
        <>
            <button className="o-advertiser__trigger" onClick={props.onClick}>Advertiser Disclosure</button>
            <Modal
                className="o-advertiser__modal"
                isOpen={props.isOpen}
                onRequestClose={props.onClose}
            >
                <Modal.Header>
                    <Typography component="h2" variant="h2">Advertiser Disclosure</Typography>
                </Modal.Header>
                <Modal.Body>
                    <Typography component="p" variant="body2">CardCruncher has partnered with CardRatings for our coverage of credit card products.</Typography>
                    <Typography component="p" variant="body2">CardCruncher and CardRatings may receive a commission from card issuers.</Typography>
                    <Typography component="p" variant="body2">This is how we offer CardCruncher for free to our users! Commissions do not affect or prioritize placement within our results and not all cards displayed earn us a commission.</Typography>
                    <Typography component="p" variant="body2">This content is not provided by any bank advertiser. Opinions expressed here are author's alone, not those of the issuer, and have not been reviewed, approved or otherwise endorsed by the issuer.</Typography>
                </Modal.Body>
            </Modal>
        </>
    )
}
