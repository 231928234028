import React from "react"
import { Typography } from "../../../atoms"
import { List } from "../../../molecules"
import { Paper } from "../../../organisms"

const Proto = ({ title, data }) => (
    <Paper className="m-pros" bgLight>
        <Typography
            className="--mb-1"
            component="h4"
            variant="overline"
        >
            {title}
        </Typography>
        <List variant="bullet">
            {data.map(item => <li key={item}>{item}</li>)}
        </List>
    </Paper>
)

export function ProsAndCons({ cons, pros }) {
    if (!cons.length && !pros.length) return null

    return (
        <>
            {pros.length && <Proto title="Pros" data={pros} />}
            {cons.length && <Proto title="Cons" data={cons} />}
        </>
    )
}
