import React, { Suspense } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Loader } from '../../molecules';
import { Header, Footer } from '../../organisms';
import { Main } from '../grid';
import { isDev, isSnap } from '../../../config/api-services-config';
import PlaidContainer from '../../organisms/plaid/container';
import GtmScript from '../../scripts/google-tag-manager';
import GtoScript from '../../scripts/google-tag-optimize'
import OneSignalScript from '../../scripts/one-signal';
import { getAllUrlParams } from '../../../utilities';
import { storeCookie } from '../../../redux/actions/__deprecated-manual-crunch';

import './main.scss';

const MainTemplate = ({ children, root, storeCookie }) => {
    const [cookies, setCookie] = useCookies(null);

    React.useEffect(() => {
        const { utm_campaign, utm_medium, utm_source, var2 } = getAllUrlParams(window.location.href);

        if (utm_campaign) {
            setCookie('ccUtmCampaign', utm_campaign, { path: '/' });
            storeCookie({
                name: 'utm_campaign',
                value: utm_campaign,
            });
        } else {
            if (cookies.ccUtmCampaign !== undefined && cookies.ccUtmCampaign !== '') {
                storeCookie({
                    name: 'utm_campaign',
                    value: cookies.ccUtmCampaign,
                });
            }
        }

        if (utm_medium) {
            setCookie('ccUtmMedium', utm_medium, { path: '/' });
            storeCookie({
                name: 'utm_medium',
                value: utm_medium,
            });
        } else {
            if (cookies.ccUtmMedium !== undefined && cookies.ccUtmMedium !== '') {
                storeCookie({
                    name: 'utm_medium',
                    value: cookies.ccUtmMedium,
                });
            }
        }

        if (utm_source) {
            setCookie('ccUtmSource', utm_source, { path: '/' });
            storeCookie({
                name: 'utm_source',
                value: utm_source,
            });
        } else {
            if (cookies.ccUtmSource !== undefined && cookies.ccUtmSource !== '') {
                storeCookie({
                    name: 'utm_source',
                    value: cookies.ccUtmSource,
                });
            }
        }

        if (var2) {
            setCookie('ccVar2', var2, { path: '/' });
            storeCookie({
                name: 'var2',
                value: var2,
            });
        } else {
            if (cookies.ccVar2 !== undefined && cookies.ccVar2 !== '') {
                storeCookie({
                    name: 'var2',
                    value: cookies.ccVar2,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <Main className='t-main'>
                <Suspense fallback={<Loader isVisible={true} variant='fixed' />}>
                    {children}
                </Suspense>
            </Main>
            <Footer />
            {!isSnap &&
                <>
                    <GtmScript />
                    <GtoScript />
                    <PlaidContainer />
                    {!isDev && <OneSignalScript />}
                </>
            }
        </>
    )
}

const mapStateToProps = ({ root }) => ({
    root: root,
});

const mapDispatchToProps = dispatch => ({
    storeCookie: cookie => dispatch(storeCookie(cookie)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MainTemplate);
