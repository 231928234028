import React from "react"
// import PropTypes from "prop-types"
import cx from "classnames"
import PieChart from "react-minimal-pie-chart"
import { Typography } from "../../atoms"
import { calculateTotalsFromComponents } from "../../../utilities/index"

import "./crunch-values-piechart.scss"

const COLORS = [ "#6293ad", "#80cde4", "#76e9ea", "#1d4d66" ]

function CrunchValuesPiechart(props) {
    const classes = cx(
        props.className,
        "o-crunch-values-piechart",
    )
    const firstThree = props.transactionComponents.slice(0, 3)
    const lastFour = props.transactionComponents.slice(3)
    const { transactionCrunchValue } = calculateTotalsFromComponents(lastFour)

    const data = []
    firstThree.forEach((item, key) => data.push({
        title: item.name,
        value: item.crunch_value,
        color: COLORS[key]
    }))

    if (data[0].value === 0 &&
        data[1].value === 0 &&
        data[2].value === 0) {
        data.push({
            title: "Other",
            value: 1,
            color: COLORS[3],
        })
    } else {
        data.push({
            title: "Other",
            value: transactionCrunchValue,
            color: COLORS[3],
        })
    }

    return (
       <div className={classes}>
           <PieChart data={data} />
            <Typography accent="gray-3" component="p" variant="caption">
                Crunch Value is the estimated value you’d earn over a year
                with this card if your past spending habits are maintained.
            </Typography>
       </div>
    )
}

// data={[
//     { title: 'Two', value: 10, color: '#5294B0' },
//     { title: 'Three', value: 6, color: '#044E68' },
//     { title: 'Three', value: 6, color: '#37EDEC' },
//     { title: 'One', value: 6, color: '#64CFE7' },
// ]}

export default CrunchValuesPiechart

// CrunchValuesPiechart.defaultProps = {}

// CrunchValuesPiechart.propTypes = {}
