import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LazyImage from '../../atoms/lazy-image/__hook__lazy-image';
import { Button, Typography } from '../../atoms';
import { Highlight, Rating } from '../../molecules';
import { formatData, getCardRelativeURL } from '../../../utilities/utils';
import { calculateTotalsFromComponents } from '../../../utilities';
import { List } from '../../molecules';
import { Paper } from '../../organisms';
import { Earn, Redeem, Rewards, Optimize } from '../../pages/card-details/components/rewards';

import './offer.scss';

function formatCurrency(x) {
  return parseFloat(x)
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const CardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11">
      <path
        fill="#f39e1d"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M14.063 15H.937A.927.927 0 010 14.083V9.347c0-.253.21-.458.469-.458H14.53c.26 0 .469.205.469.458v4.736c0 .507-.42.917-.938.917zm.468-8.556H.47A.464.464 0 010 5.986v-1.07C0 4.41.42 4 .938 4h13.124c.518 0 .938.41.938.917v1.07c0 .252-.21.457-.469.457z"
        transform="translate(-150 -1796) translate(150 1792)"
      ></path>
    </svg>
  );
};

const CrunchValues = ({ crunch_values }) => {
  return (
    <div className="o-offer__highlights">
      {crunch_values.total_crunch_value !== undefined && (
        <Highlight
          className="--color-primary"
          title="Crunch Value"
          value={`$${crunch_values.total_crunch_value.toFixed(2)}`}
        />
      )}
      {crunch_values.transactionCrunchValue !== undefined && (
        <Highlight
          title="Total Rewards"
          v
          b
          value={`$${formatData(crunch_values.transactionCrunchValue)}`}
        />
      )}
      {crunch_values.totalBonus !== undefined && (
        <Highlight title="Bonus Amount" value={`$${crunch_values.totalBonus.toFixed(0)}`} />
      )}
      {crunch_values.totalFees !== undefined && (
        <Highlight title="Annual Fee" value={`$${crunch_values.totalFees.toFixed(0)}`} />
      )}
    </div>
  );
};

const OfferImage = ({ alt, src, onClick }) => (
  <LazyImage className="o-offer__image" alt={alt} src={src} onClick={onClick} />
);

const OfferCaption = ({
  isBestChoice,
  isCrunched,
  full_name,
  offerUrl,
  // min_credit_score,
  user_has_connected_plaid,
  review_score,
  onViewDetailsClick
}) => (
  <div className="o-offer__caption">
    {user_has_connected_plaid && isBestChoice ? (
      <Typography accent="gray-3" component="b" variant="overline" transform="uppercase">
        <CardIcon />
        Your Best Choice
      </Typography>
    ) : (
      ''
    )}
    {/* {min_credit_score} */}
    <a href={offerUrl} onClick={onViewDetailsClick} aria-label={full_name}>
      <Typography component="h3" variant="subtitle2">
        {full_name}
      </Typography>
    </a>
    <Rating score={review_score} text="Expert Review" />
  </div>
);

const OfferCta = ({
  apply_button_hide_background,
  apply_button_text,
  apply_button_subtext,
  apply_now_url,
  issuer,
  url,
  onClick
}) => {
  const renderButtonText = () => {
    if (apply_button_text === '') return null;
    return apply_button_text === null ? 'Apply Now' : apply_button_text;
  };

  const renderButtonSubtext = () => {
    if (apply_button_subtext === '') return null;
    return apply_button_subtext === null ? `on ${issuer}'s Secure Site` : apply_button_subtext;
  };

  return (
    <div className="o-offer__cta">
      {apply_button_hide_background ? (
        <a
          className="a-button a-button--block --link"
          href={apply_now_url}
          target="_blank"
          rel="nofollow noopener noreferrer"
          onClick={onClick}
        >
          {renderButtonText()}
        </a>
      ) : (
        <a
          className="a-button a-button--primary a-button--block"
          href={apply_now_url}
          target="_blank"
          rel="nofollow noopener noreferrer"
          onClick={onClick}
        >
          {renderButtonText()}
        </a>
      )}
      <Typography accent="gray-1" component="p" variant="caption">
        {renderButtonSubtext()}
      </Typography>
    </div>
  );
};

function Offer(props) {
  const [activeTab, setActiveTab] = React.useState('Our Take');
  const [seeMore, setSeeMore] = React.useState(false);
  const classes = cx(`o-offer`, { [`has-crunch-values`]: !!props.crunch_values }, props.className);
  const offerUrl = getCardRelativeURL(props.issuer, props.slug, props.id);

  const handleTabClick = event => {
    const value = event.target.dataset.value;
    setActiveTab(value);

    event.preventDefault();
  };

  const handleSeeMoreClick = () => {
    setSeeMore(true);
  };

  const renderCrunchedValues = () => {
    if (props.crunch_values) {
      return (
        <>
          <CrunchValues crunch_values={props.crunch_values} />
          <div className="o-offer__cta">
            <a href={offerUrl} onClick={props.onViewDetailsClick} aria-label={props.full_name}>
              View details
            </a>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="o-offer__highlights is-default">
          {/* <span>CardCruncher finds the best cards for you.</span> */}
          <div className="m-highlight">
            <Typography component="h4" variant="overline">
              Bonus Amount
            </Typography>
            <Typography component="p" variant="body2" weight="500">
              {props.extra_details.cardHighlights.bonusAmount}
            </Typography>
            <small>{props.extra_details.cardHighlights.bonusAmount2}</small>
          </div>
          <div className="m-highlight">
            <Typography component="h4" variant="overline">
              Annual Fees
            </Typography>
            <Typography component="p" variant="body2" weight="500">
              {props.extra_details.cardHighlights.annualFees}
            </Typography>
            <small>{props.extra_details.cardHighlights.annualFees2}</small>
          </div>
        </div>
        <div className="o-offer__cta is-default">
          <Typography component="h4" variant="overline">
            Crunch Value
          </Typography>
          <Button variant="outline-dark" onClick={props.onConnectClick}>
            Connect
          </Button>
          <small>CardCruncher finds the best cards for you.</small>
        </div>
      </>
    );
  };

  const renderOurTakeTab = () => {
    const { cardAnalysis, ourTake } = props.extra_details;

    // if (ourTake.text === '' || cardAnalysis.) return null;

    const renderOurTake = () => {
      if (ourTake.text === '') return null;

      return (
        <Typography component="p" variant="body2">
          {ourTake.text}
        </Typography>
      );
    };

    const renderSummary = () => {
      if (cardAnalysis.analysisDescription === '') return null;

      return (
        <div className="more-details">
          <div className={`more-details__content ${seeMore ? 'a' : 'b'}`}>
            <div
              className="rte-content"
              dangerouslySetInnerHTML={{ __html: cardAnalysis.analysisDescription }}
            />
          </div>
          {!seeMore && (
            <div className="more-details__link" onClick={handleSeeMoreClick}>
              See more
            </div>
          )}
        </div>
      );
    };

    return (
      <div className="tabs__panel">
        {renderOurTake()}
        {renderSummary()}
      </div>
    );
  };

  const renderCardDetailsTab = () => {
    const { cons, pros } = props.extra_details.prosAndCons;

    return (
      <div className="tabs__panel">
        <div className="pros-and-cons">
          <Paper bgLight>
            <Typography className="--mb-1" component="h4" variant="overline">
              Pros
            </Typography>
            <List variant="bullet">
              {pros.length > 0 ? pros.map(item => <li key={item}>{item}</li>) : <li>No data</li>}
            </List>
          </Paper>
          <Paper bgLight>
            <Typography className="--mb-1" component="h4" variant="overline">
              Cons
            </Typography>
            <List variant="bullet">
              {cons.length > 0 ? cons.map(item => <li key={item}>{item}</li>) : <li>No Data</li>}
            </List>
          </Paper>
        </div>
      </div>
    );
  };

  const renderYourRewardsTab = () => {
    // isFakeCrunched
    // user_has_connected_plaid
    if (props.isFakeCrunched || props.user_has_connected_plaid) {
      const { transactionComponents } = calculateTotalsFromComponents(props.by_component);

      const rows = transactionComponents.map(d => (
        <tr className={d.name} key={d.name}>
          <td className="is-category">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <i className="cui-spending-icon" /> */}
              <span>{d.name}</span>
            </div>
          </td>
          <td className="is-your-spending" data-title="Your spending">
            ${formatCurrency(d.total_spending)}
          </td>
          <td className="is-cashback" data-title="Cashback">
            {formatData(d.cashback_factor * 100)}%
          </td>
          <td className="is-rewards" data-title="Rewards">
            ${formatCurrency(d.crunch_value)}
          </td>
        </tr>
      ));

      return (
        <div className="tabs__panel">
          <table>
            <thead>
              <tr>
                <th className="is-category">
                  <Typography variant="overline">Category</Typography>
                </th>
                <th className="is-your-spending">
                  <Typography variant="overline">Your spending</Typography>
                </th>
                <th className="is-cashback">
                  <Typography variant="overline">Effective return</Typography>
                </th>
                <th className="is-rewards">
                  <Typography variant="overline">Rewards value</Typography>
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      );
    }

    const isRewardEarningsVisible = () => {
      const { rewardEarnings } = props.extra_details;
      return (
        !!rewardEarnings.length &&
        rewardEarnings[0].field !== undefined &&
        !!rewardEarnings[0].field
      );
    };

    const isHowToEarnVisible = () => {
      return (
        !!props.extra_details.howToEarn.text !== undefined && !!props.extra_details.howToEarn.text
      );
    };

    const isHowToRedeemVisible = () => {
      const { howToRedeem } = props.extra_details;
      return !!howToRedeem.length && !!howToRedeem[0].text !== undefined && !!howToRedeem[0].text;
    };

    const isHowToOptimizeVisible = () => {
      const { howToOptimize } = props.extra_details;
      return !!howToOptimize.text !== undefined && !!howToOptimize.text;
    };

    return (
      <div className="tabs__panel">
        {isRewardEarningsVisible() && (
          <Rewards rewardEarnings={props.extra_details.rewardEarnings} />
        )}
        {isHowToEarnVisible() && <Earn text={props.extra_details.howToEarn.text} />}
        {isHowToRedeemVisible() && <Redeem howToRedeem={props.extra_details.howToRedeem} />}
        {isHowToOptimizeVisible() && <Optimize text={props.extra_details.howToOptimize.text} />}
      </div>
    );
  };

  const renderTabs = () => {
    const renderOurTakeNav = () => {
      if (
        props.extra_details.ourTake.text === '' &&
        props.extra_details.cardAnalysis.analysisDescription === ''
      ) {
        return null;
      }

      return (
        <a
          href="#our-take"
          className={`a-overline --uppercase ${activeTab === 'Our Take' ? 'is-active' : ''}`}
          onClick={handleTabClick}
          data-value="Our Take"
        >
          Our Take
        </a>
      );
    };

    return (
      <div className="tabs">
        <div className="tabs__navigation">
          <div className="tabs__navigation__container">
            {renderOurTakeNav()}
            <a
              href="#pros-cons"
              className={`a-overline --uppercase ${activeTab === 'Pros/Cons' ? 'is-active' : ''}`}
              onClick={handleTabClick}
              data-value="Pros/Cons"
            >
              Pros/Cons
            </a>
            <a
              href="#Your Rewards"
              className={`a-overline --uppercase ${
                activeTab === 'Your Rewards' ? 'is-active' : ''
              }`}
              onClick={handleTabClick}
              data-value="Your Rewards"
            >
              Your Rewards
            </a>
          </div>
        </div>
        <div className="tabs__content">
          {activeTab === 'Our Take' && renderOurTakeTab()}
          {activeTab === 'Pros/Cons' && renderCardDetailsTab()}
          {activeTab === 'Your Rewards' && renderYourRewardsTab()}
        </div>
      </div>
    );
  };

  return (
    <article className={classes}>
      <OfferImage alt={props.full_name} src={props.image} onClick={props.onViewDetailsClick} />
      <div className="o-offer__body">
        {/* {props.extraDetails.recommendedCreditScore.suggestedCredit} */}
        <OfferCaption
          isBestChoice={props.isBestChoice}
          isCrunched={props.isCrunched}
          full_name={props.full_name}
          offerUrl={offerUrl}
          // min_credit_score={props.min_credit_score}
          user_has_connected_plaid={props.user_has_connected_plaid}
          review_score={props.review_score}
          onViewDetailsClick={props.onViewDetailsClick}
          onApplyNowClick={props.onApplyNowClick}
        />
        <OfferCta
          apply_now_url={props.apply_now_url}
          apply_button_hide_background={props.apply_button_hide_background}
          apply_button_text={props.apply_button_text}
          apply_button_subtext={props.apply_button_subtext}
          full_name={props.full_name}
          issuer={props.issuer}
          url={offerUrl}
          onClick={props.onApplyNowClick}
        />
      </div>
      {renderCrunchedValues()}
      {renderTabs()}
    </article>
  );
}

export default Offer;

Offer.propTypes = {
  full_name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  review_score: PropTypes.string.isRequired,
  payment_network: PropTypes.string.isRequired
};
