import { push } from 'react-router-redux'

export const storeCookie = cookie => ({
    type: "STORE_COOKIE",
    cookie
});

export const storeManualCrunch = profile => ({
    type: "STORE_MANUAL_CRUNCH",
    profile
})

export const manualCrunch = profile => (
    dispatch => {
        dispatch(storeManualCrunch(profile))
        dispatch(push('/top-picks'))
    }
)
