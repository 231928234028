import React from "react"
import cx from "classnames"

import "./form-row.scss"

function FormRow(props) {
    const classes = cx(
        `m-form-row`,
        props.className,
    )

    return (
        <div className={classes}>{props.children}</div>
    )
}

export default FormRow
