export const plaidLoaded = () => (
    dispatch => {
        dispatch({ type: "PLAID_LOADED" })
    }
)

export function openPlaid() {
    if (window.plaidHandler) {
        window.plaidHandler.open()
    }

    return dispatch => dispatch({
        type: "OPEN_PLAID"
    })
}

export function closePlaid() {
    if (window.plaidHandler) {
        window.plaidHandler.exit()
    }

    return dispatch => dispatch({
        type: "CLOSE_PLAID"
    })
}