import { push } from "react-router-redux"
import actions from "./"
import { getCardDetailsApi } from "../../api"
import { getCardRelativeURL } from "../../utilities/utils"

export const getCardDetails = (id, sameOrigin) => dispatch => {
    dispatch({ type: actions.REQUEST_CARD_DETAILS })

    return getCardDetailsApi(id)
        .then(payload => dispatch({
            type: actions.RECEIVE_CARD_DETAILS,
            payload
        }))
        .then(payload => {
            const { issuer, slug, id } = payload.payload
            const cardUrl = getCardRelativeURL(issuer, slug, id)

            if (!sameOrigin) dispatch(push(`/${cardUrl}`))
        })
        .catch(error => { throw(error) })
}

export const resetCardDetails = () => dispatch =>
    dispatch({ type: actions.RESET_CARD_DETAILS })
