import React from 'react';
import Moment from 'react-moment';
import Scroll from 'react-scroll';
import { LazyImage, Typography } from '../../atoms';
import { List, Rating } from '../../molecules';
import { AdvertiserDisclosureModal, Hero, Panel, ManualCrunch } from '../../organisms';
import { Container, Row } from '../../templates';
import { AdditionalBenefits, CardBenefits } from './components/benefits';
import { CreditScore } from './components/credit-score';
import { Highlights } from './components/highlights';
import { Fees } from './components/fees';
import { ProsAndCons } from './components/pros-and-cons';
import { RelatedCards } from './components/related-cards';
import { Earn, Redeem, Rewards, Optimize } from './components/rewards';
import { Summary } from './components/summary';

const TableOfContents = props => (
  <Panel className="with-gradient with-gradient--2 o-table-of-contents">
    <Panel.Header>
      <h3 className="o-panel__title">Table of Contents</h3>
    </Panel.Header>
    <Panel.Body>
      <List variant="decimal">
        {props.isOurTakeVisible && (
          <li>
            <Scroll.Link
              activeClass="is-active"
              to="our-take"
              href="/#our-take"
              spy={true}
              smooth={true}
              offset={-132}
              duration={1000}
            >
              Our Take
            </Scroll.Link>
          </li>
        )}
        {props.isSummaryVisible && (
          <li>
            <Scroll.Link
              activeClass="is-active"
              to="summary"
              href="/#summary"
              spy={true}
              smooth={true}
              offset={-132}
              duration={1000}
            >
              Summary
            </Scroll.Link>
          </li>
        )}
        {props.isRewardsVisible && (
          <li>
            <Scroll.Link
              activeClass="is-active"
              to="rewards"
              href="/#rewards"
              spy={true}
              smooth={true}
              offset={-132}
              duration={1000}
            >
              Rewards
            </Scroll.Link>
          </li>
        )}
        {props.isBenefitsVisible && (
          <li>
            <Scroll.Link
              activeClass="is-active"
              to="benefits"
              href="/#benefits"
              spy={true}
              smooth={true}
              offset={-132}
              duration={1000}
            >
              Benefits
            </Scroll.Link>
          </li>
        )}
      </List>
    </Panel.Body>
  </Panel>
);

const Aside = ({
  onManualCrunchSubmit,
  isOurTakeVisible,
  isSummaryVisible,
  isRewardsVisible,
  isBenefitsVisible
}) => (
  <aside className="p-card__aside">
    <TableOfContents
      isOurTakeVisible={isOurTakeVisible}
      isSummaryVisible={isSummaryVisible}
      isRewardsVisible={isRewardsVisible}
      isBenefitsVisible={isBenefitsVisible}
    />
    <Panel className="with-gradient with-gradient--1">
      <Panel.Body>
        <ManualCrunch onSubmit={onManualCrunchSubmit} />
      </Panel.Body>
    </Panel>
  </aside>
);

export function CardDetails(props) {
  const heroHeightRef = React.useRef(null);
  const Element = Scroll.Element;
  const { extra_details } = props;
  let applyNowUrl = props.apply_now_url;

  if (props.cookies.var2) {
    const var2 = props.cookies.var2;
    const a = applyNowUrl.indexOf('?') !== -1;
    if (a) {
      applyNowUrl = `${applyNowUrl};&var2=${var2}`;
    } else {
      const b = applyNowUrl;
      const c = b.slice(b.length - 1);

      if (c === '/') {
        const d = b.slice(0, -1);
        applyNowUrl = `${d}?;var2=${var2}`;
      } else {
        applyNowUrl = `${applyNowUrl}?;var2=${var2}`;
      }
    }
  }

  const isAdditionalBenefitsVisible = () => {
    const { additionalBenefits } = extra_details;
    return additionalBenefits.length && !!additionalBenefits[0].text;
  };

  const isBenefitsVisible = () => isCardBenefitsVisible() || isAdditionalBenefitsVisible();

  const isCardBenefitsVisible = () => {
    const { cardBenefits } = extra_details;
    return cardBenefits.length && !!cardBenefits[0].title;
  };

  const isHowToEarnVisible = () => {
    return !!extra_details.howToEarn.text !== undefined && !!extra_details.howToEarn.text;
  };

  const isHowToRedeemVisible = () => {
    const { howToRedeem } = extra_details;
    return !!howToRedeem.length && !!howToRedeem[0].text !== undefined && !!howToRedeem[0].text;
  };

  const isHowToOptimizeVisible = () => {
    const { howToOptimize } = extra_details;
    return !!howToOptimize.text !== undefined && !!howToOptimize.text;
  };

  const isOurTakeVisible = () => {
    const { ourTake } = extra_details;
    return !!ourTake.text !== undefined && !!ourTake.text;
  };

  const isRewardsVisible = () => {
    return (
      isRewardEarningsVisible() ||
      isHowToEarnVisible() ||
      isHowToRedeemVisible() ||
      isHowToOptimizeVisible()
    );
  };

  const isRewardEarningsVisible = () => {
    const { rewardEarnings } = extra_details;
    return (
      !!rewardEarnings.length && rewardEarnings[0].field !== undefined && !!rewardEarnings[0].field
    );
  };

  const isSummaryVisible = () => !!extra_details.cardAnalysis.analysisDescription;

  const renderButtonText = () => {
    if (props.apply_button_text === '') return null;

    if (props.apply_button_text === null) return 'Apply Now';

    return props.apply_button_text;
  };

  const renderButtonSubtext = () => {
    if (props.apply_button_subtext === '') return null;

    if (props.apply_button_subtext === null) {
      return (
        <Typography accent="gray-1" component="p" variant="caption">
          on {props.issuer}'s Secure Site
        </Typography>
      );
    }

    return (
      <Typography accent="gray-1" component="p" variant="caption">
        {props.apply_button_subtext}
      </Typography>
    );
  };

  const renderStickyHeader = () => {
    return (
      <div className="--sticky">
        <Container>
          <article className="o-offer --ls">
            <div className="o-offer__body">
              <div className="o-offer__caption">
                <Typography component="h3" variant="subtitle2">
                  {props.full_name}
                </Typography>
                <Rating score={props.review_score} text="Expert Review" />
              </div>
              <div className="o-offer__cta">
                {props.apply_button_hide_background ? (
                  <>
                    <a
                      className="a-button a-button--block --link"
                      href={applyNowUrl}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      onClick={() =>
                        props.onApplyNowClick(props.full_name, props.issuer, props.id, applyNowUrl)
                      }
                    >
                      {renderButtonText()}
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      className="a-button a-button--primary a-button--block"
                      href={applyNowUrl}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      onClick={() =>
                        props.onApplyNowClick(props.full_name, props.issuer, props.id, applyNowUrl)
                      }
                    >
                      {renderButtonText()}
                    </a>
                  </>
                )}
              </div>
            </div>
          </article>
        </Container>
      </div>
    );
  };

  React.useEffect(() => {
    const h = heroHeightRef.current.offsetTop;
    props.onTopHeight(h);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {renderStickyHeader()}
      <Hero className="p-card__hero" size="md">
        <Hero.Content>
          <AdvertiserDisclosureModal
            isOpen={props.isModalOpen}
            onClick={props.onAdvertiserClick}
            onClose={props.onModalClose}
          />
          <Typography component="h1" variant="h2">
            {props.full_name}
          </Typography>
          <Rating score={props.review_score} text="Expert Reviews" />
          {props.apply_button_hide_background ? (
            <>
              <a
                className="a-button a-button--block --link"
                href={applyNowUrl}
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() =>
                  props.onApplyNowClick(props.full_name, props.issuer, props.id, applyNowUrl)
                }
              >
                {renderButtonText()}
              </a>
              {renderButtonSubtext()}
            </>
          ) : (
            <>
              <a
                className="a-button a-button--primary a-button--block"
                href={applyNowUrl}
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() =>
                  props.onApplyNowClick(props.full_name, props.issuer, props.id, applyNowUrl)
                }
              >
                {renderButtonText()}
              </a>
              {renderButtonSubtext()}
            </>
          )}
        </Hero.Content>
        <Hero.Object>
          <LazyImage srcPreload={props.image} alt={props.full_name} />
        </Hero.Object>
        <svg
          className="o-hero__watermark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1449 1076"
        >
          <defs>
            <linearGradient id="a" x1="55.987%" x2="67.072%" y1="38.898%" y2="66.517%">
              <stop offset="0%" stopColor="#4B90CD" stopOpacity="0.028" />
              <stop offset="100%" stopColor="#17468F" stopOpacity="0.434" />
            </linearGradient>
          </defs>
          <path
            fill="url(#a)"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="1"
            d="M777 1010l275.743-284 6.803 7.433c28.939 32.028 42.642 75.993 37.33 119.768-3.661 32.264-17.233 62.401-38.665 85.86L989.917 1010H777zm146.651-421L1004 676.384S680.44 1009.406 679.952 1010H514l409.651-421zm-822.848 46.129C49.146 577.706 49.055 488.536 100.596 431L534 897.889 436.73 997 100.804 635.129zM666.974 67L391.257 351l-6.803-7.425c-28.649-31.777-42.382-75.307-37.477-118.794 3.454-32.816 17.106-63.516 38.838-87.341l68.281-70.413H667l-.026-.027zm-146.63 421L440 400.458S763.542 67.595 764.031 67H930L520.345 488zm825.867-46.129c51.649 57.43 51.728 146.6.181 204.129L913 179.111 1010.267 80l335.944 361.871zM722.44 708L566 538.516 728.56 372 885 541.457 722.44 708zM281.169 243c.35 54.651 20.67 107.095 56.8 146.594L672 751.799 579.236 847 148 379.499 281.169 243zM772 325.083L864.777 230 1296 697.477 1162.837 834c-.35-54.66-20.673-107.114-56.807-146.62L772 325.083zm334.764 660.1l283.69-289.3c77.376-83.825 78.15-215.97 1.76-300.767L1034.534 9.507c-6.25-6.247-14.624-9.664-23.291-9.501H443.655c-8.445.014-16.548 3.474-22.567 9.636l-78.725 80.292L58.594 379.208c-77.44 83.838-78.214 216.062-1.76 300.874l357.577 385.501c6.12 6.56 14.504 10.31 23.292 10.417h567.665c8.536-.325 16.62-4.067 22.567-10.444l78.726-80.292.103-.08z"
          />
        </svg>
      </Hero>
      <div ref={heroHeightRef} />
      <section className="p-card__content">
        <Container>
          <Row>
            <Row.Col md="8" lg="8">
              <Panel onlyBody>
                <Panel.Body>
                  <Typography
                    className="p-card__last-updated"
                    accent="gray-1"
                    component="p"
                    variant="caption"
                  >
                    Last Updated: <Moment format="MMM DD, YYYY">{props.last_updated}</Moment>
                  </Typography>
                  {isOurTakeVisible() && (
                    <Element name="our-take">
                      <Typography className="o-panel__title" component="h2" variant="h3">
                        Our Take
                      </Typography>
                      <div
                        className="a-body2"
                        dangerouslySetInnerHTML={{ __html: extra_details.ourTake.text }}
                        style={{ marginBottom: '32px' }}
                      />
                    </Element>
                  )}
                  <Highlights {...extra_details.cardHighlights} />
                  <Fees {...extra_details.apr} {...extra_details.fees} />
                  <Element name="summary">
                    <Row>
                      <Row.Col md="8" lg="8">
                        <Summary
                          analysisDescription={extra_details.cardAnalysis.analysisDescription}
                        />
                      </Row.Col>
                      <Row.Col md="4" lg="4">
                        <CreditScore
                          minCreditScore={props.min_credit_score}
                          suggestedCredit={extra_details.recommendedCreditScore.suggestedCredit}
                        />
                        <ProsAndCons {...extra_details.prosAndCons} />
                      </Row.Col>
                    </Row>
                  </Element>
                </Panel.Body>
                {isRewardsVisible() && (
                  <Panel.Body>
                    <Element name="rewards">
                      <Typography className="o-panel__title" component="h2" variant="h3">
                        Earn, Redeem, and Optimize Your Rewards
                      </Typography>
                      {isRewardEarningsVisible() && (
                        <Rewards rewardEarnings={extra_details.rewardEarnings} />
                      )}
                      {isHowToEarnVisible() && <Earn text={extra_details.howToEarn.text} />}
                      {isHowToRedeemVisible() && <Redeem howToRedeem={extra_details.howToRedeem} />}
                      {isHowToOptimizeVisible() && (
                        <Optimize text={extra_details.howToOptimize.text} />
                      )}
                    </Element>
                  </Panel.Body>
                )}
                {isBenefitsVisible() && (
                  <Panel.Body>
                    <Element name="benefits">
                      <Row>
                        <Row.Col md="8" lg="8">
                          <Typography className="o-panel__title" component="h2" variant="h3">
                            Benefits
                          </Typography>
                          <CardBenefits cardBenefits={extra_details.cardBenefits} />
                        </Row.Col>
                        <Row.Col md="4" lg="4">
                          <AdditionalBenefits
                            additionalBenefits={extra_details.additionalBenefits}
                          />
                        </Row.Col>
                      </Row>
                    </Element>
                  </Panel.Body>
                )}
                {extra_details.relatedCardsCarousel.cards.length && (
                  <Panel.Body>
                    <Typography className="o-panel__title" component="h2" variant="h3">
                      {extra_details.relatedCardsCarousel.title}
                    </Typography>
                    <RelatedCards
                      cards={extra_details.relatedCardsCarousel.cards}
                      onRelatedCardClick={props.onRelatedCardClick}
                    />
                  </Panel.Body>
                )}
              </Panel>
            </Row.Col>
            <Row.Col md="4" lg="4">
              <Aside
                isOurTakeVisible={isOurTakeVisible()}
                isSummaryVisible={isSummaryVisible()}
                isRewardsVisible={isRewardsVisible()}
                isBenefitsVisible={isBenefitsVisible()}
                onManualCrunchSubmit={props.onManualCrunchSubmit}
              />
            </Row.Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
