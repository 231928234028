const REACT_APP_VERSION = '0.3.4';

function loadState() {
  let authState = {};
  let crunchState = {};
  const deployVersion = localStorage.getItem('ccDeployVersion');
  const newDeployVersion = REACT_APP_VERSION; //process.env.REACT_APP_VERSION

  // console.log("loadState ---REACT_APP_VERSION localStorage---", localStorage.getItem('ccDeployVersion'))
  // console.log("loadState ---REACT_APP_VERSION---", REACT_APP_VERSION)

  try {
    const serializedState = localStorage.getItem('authState');
    if (serializedState === null) {
      authState = {};
    }

    if (deployVersion !== newDeployVersion) {
      authState = {};
    } else {
      authState = JSON.parse(serializedState);
    }
  } catch (err) {}

  try {
    const serializedState = sessionStorage.getItem('crunchState');
    if (serializedState === null) {
      crunchState = {};
    }

    if (deployVersion !== newDeployVersion) {
      crunchState = {};
    } else {
      crunchState = JSON.parse(serializedState);
    }
  } catch (err) {}

  const state = Object.assign(authState || {}, crunchState || {});
  return state;
}

const saveState = state => {
  let authState = {};
  let crunchState = {};
  const deployVersion = localStorage.getItem('ccDeployVersion');
  const newDeployVersion = REACT_APP_VERSION; //process.env.REACT_APP_VERSION

  // console.log("saveState ---REACT_APP_VERSION localStorage---", deployVersion)
  // console.log("saveState ---REACT_APP_VERSION---", REACT_APP_VERSION)

  if (deployVersion !== newDeployVersion) {
    authState = {
      login_token: null,
      fake_crunch_profile: {
        dining: 200,
        travel: 75,
        groceries: 200,
        gas_stations: 25,
        entertainment: 50,
        pharmacy: 25,
        other: 125
      },
      user_has_connected_social: false,
      user_has_connected_plaid: false
    };

    crunchState = {
      crunch_data: null,
      crunch_prefetch_fpr_crunch_data: null,
      crunch_prefetch_sfy_crunch_data: null,
      crunch_prefetch_fpr_and_sfy_crunch_data: null,
      fake_prefetch_fpr_crunch_data: null,
      fake_prefetch_sfy_crunch_data: null,
      fake_prefetch_fpr_and_sfy_crunch_data: null
    };

    localStorage.setItem('ccDeployVersion', newDeployVersion);
  } else {
    authState = {
      login_token: state.root.login_token,
      fake_crunch_profile: state.root.fake_crunch_profile,
      user_has_connected_social: state.root.user_has_connected_social,
      user_has_connected_plaid: state.root.user_has_connected_plaid
    };

    crunchState = {
      crunch_data: state.root.crunch_data,
      crunch_prefetch_fpr_crunch_data: state.root.crunch_prefetch_fpr_crunch_data,
      crunch_prefetch_sfy_crunch_data: state.root.crunch_prefetch_sfy_crunch_data,
      crunch_prefetch_fpr_and_sfy_crunch_data: state.root.crunch_prefetch_fpr_and_sfy_crunch_data,
      fake_prefetch_fpr_crunch_data: state.root.fake_prefetch_fpr_crunch_data,
      fake_prefetch_sfy_crunch_data: state.root.fake_prefetch_sfy_crunch_data,
      fake_prefetch_fpr_and_sfy_crunch_data: state.root.fake_prefetch_fpr_and_sfy_crunch_data
    };
  }

  try {
    const serializedAuthState = JSON.stringify(authState);
    localStorage.setItem('authState', serializedAuthState);

    const serializedCrunchState = JSON.stringify(crunchState);
    sessionStorage.setItem('crunchState', serializedCrunchState);
  } catch (err) {}
};

function getComponentType(array, component) {
  return array.filter(c => c.component_type === component);
}

function getSum(array, component) {
  return array.map(c => parseFloat(c[component])).reduce((a, b) => a + b, 0);
}

function calculateTotalsFromComponents(components) {
  const annualCreditComponents = getComponentType(components, 'annual_credit');
  const bonusComponents = getComponentType(components, 'bonus');
  const categoryComponents = getComponentType(components, 'category');
  const feeComponents = getComponentType(components, 'fees');
  const merchantComponents = getComponentType(components, 'merchant');

  let transactionComponents = categoryComponents;
  if (merchantComponents.length) {
    const aggregatedMerchantComponent = {
      name: 'Retail',
      // icon_class: "ion-cash",
      crunch_value: getSum(merchantComponents, 'crunch_value'),
      total_spending: getSum(merchantComponents, 'total_spending'),
      cashback_factor: Math.max(merchantComponents.map(a => parseFloat(a.cashback_factor)))
    };
    transactionComponents.push(aggregatedMerchantComponent);
  }
  const transactionCrunchValue = getSum(transactionComponents, 'crunch_value');
  const totalBonus = getSum(bonusComponents, 'crunch_value');
  const totalCredit = getSum(annualCreditComponents, 'crunch_value');
  const totalFees = -getSum(feeComponents, 'crunch_value');
  const totalRewards = transactionCrunchValue + totalCredit;

  return {
    transactionComponents,
    transactionCrunchValue,
    totalBonus,
    totalCredit,
    totalFees,
    totalRewards
  };
}

export { calculateTotalsFromComponents, loadState, saveState };

export const getAllUrlParams = url => {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  // we'll store the parameters here
  let obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];
    // split our query string into its component parts
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');
      // set parameter name and value (use 'true' if empty)
      let paramName = a[0];
      let paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') {
        paramValue = paramValue.toLowerCase();
      }

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};
