import React from "react"
import { Typography } from "../../../atoms"
import { List } from "../../../molecules"
import { Paper } from "../../../organisms"

import EntertainmentIcon from "../../../../assets/images/icons/icon-entertainment.svg"
import GasStationIcon from "../../../../assets/images/icons/icon-gas-stations.svg"
import GroceriesIcon from "../../../../assets/images/icons/icon-groceries.svg"
import PharmacyIcon from "../../../../assets/images/icons/icon-pharmacy.svg"
import RestaurantsIcon from "../../../../assets/images/icons/icon-restaurants.svg"
import TravelIcon from "../../../../assets/images/icons/icon-travel.svg"
import OtherIcon from "../../../../assets/images/icons/icon-other.svg"

const ICONS = {
    "Entertainment": EntertainmentIcon,
    "Gas Stations": GasStationIcon,
    "Groceries": GroceriesIcon,
    "Pharmacy": PharmacyIcon,
    "Restaurants/Dining": RestaurantsIcon,
    "Travel": TravelIcon,
    "Other": OtherIcon,
}

// const Item = ({ field, multiplier, type }) => (
//     <li>
//         <div>
//             <img src={ICONS[item.field]} alt="" />
//         </div>
//         <div>{field}</div>
//         <div>{multiplier} {" "} {type}</div>
//     </li>
// )

// export function Rewards({ data }) {
//     return <ul>{data.map(item => <Item key={item.field} {...item} />)}</ul>
// }

export function Earn({ text }) {
    return (
        <div className="m-earn">
            <Typography
                className="--mb-1"
                component="h4"
                variant="overline"
            >
                Earn
            </Typography>
            <div
                className="a-body2"
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </div>
    )
}

export function Optimize({ text }) {
    return (
        <div className="m-optimize">
            <Typography
                className="--mb-1"
                component="h4"
                variant="overline"
            >
                Optimize
            </Typography>
            <div
                className="a-body2"
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </div>
    )
}

export function Redeem({ howToRedeem }) {
    return (
        <div className="m-redeem">
            <Typography
                className="--mb-1"
                component="h4"
                variant="overline"
            >
                Redeem
            </Typography>
            <List variant="bullet">
                {howToRedeem.map(({ text }) => <li key={text}>{text}</li>)}
            </List>
        </div>
    )
}

export function Rewards({ rewardEarnings }) {
    return (
        <div className="o-rewards">
            {rewardEarnings.map((item, key) => (
                <Paper className="m-reward" key={key} bgLight>
                    <div className="m-reward__icon">
                        <img src={ICONS[item.field]} alt="" />
                    </div>
                    <div className="m-reward__name">{item.field}</div>
                    <div className="m-reward__value">{item.multiplier} {" "} {item.type}</div>
                </Paper>
            ))}
        </div>
    )
}
