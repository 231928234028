import { compose } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getEnvData } from "../../../redux/actions/env"
import { plaidLoaded, plaidSuccess, plaidExit } from "../../../redux/actions/__deprecated-plaid"
import { manualCrunch } from "../../../redux/actions/__deprecated-manual-crunch"
import { openPlaid, closePlaid } from "../../../redux/actions/plaid"
import Plaid from "./plaid"

import "./index.scss"

const mapStateToProps = ({
    envData,
    plaid,
    root,
}) => ({
    envData,
    plaid,
    root,
})

const mapDispatchToProps = dispatch => ({
    getEnvData: () => dispatch(getEnvData()),
    manualCrunch: profile => dispatch(manualCrunch(profile)),
    closePlaid: () => dispatch(closePlaid()),
    openPlaid: () => dispatch(openPlaid()),
    plaidExit: (error, metadata) =>
        dispatch(plaidExit(error, metadata)),
    plaidLoaded: () => dispatch(plaidLoaded()),
    plaidSuccess: (public_token, metadata, utmObj) =>
        dispatch(plaidSuccess(public_token, metadata, utmObj)),
})

const PlaidContainer = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Plaid)
export default PlaidContainer
