import React from "react"
import { Typography } from "../../../atoms"
import { Paper } from "../../../organisms"

export function CreditScore({ minCreditScore, suggestedCredit }) {
    return (
        <Paper className="m-credit">
            <Typography
                className="m-credit__title --mb-1"
                component="h4"
                variant="overline"
            >
                Required Credit Score
            </Typography>
            <p className="m-credit__value">
                {suggestedCredit} {" "} {minCreditScore}
            </p>
            <div className="m-credit__progress">
                <div
                    className="m-credit__progress-trail"
                    style={{ width: `${minCreditScore / 850 * 100}%`}}
                />
            </div>
        </Paper>
    )
}