import React from "react"
import cx from "classnames"

import "./meta.scss";

const Item = ({ children }) => {
    if (!children) return null
    return <div className="a-meta__item">{children}</div>
}


function Meta(props) {
    const classes = cx("a-meta")

    return (
        <div className={classes}>{props.children}</div>
    )
}

export default Meta

Meta.Item = Item