import React from "react"
import PropTypes from "prop-types"
import Slider from "react-rangeslider"
import cx from "classnames"
import { createGlobalStyle } from "styled-components"

import "./range-slider.scss";

const COLORS = [
    "#4badcf",
    "#52b7d3",
    "#5bc1d5",
    "#67ccd6",
    "#74d5d6",
    "#85e0d2",
    "#97e9cb",
    "#adf2bf",
    "#cafaa8",
    "#f2ff71"
]

const Icon = ({ icon }) =>
    <div className="m-slider__icon">
        <img src={icon} alt="" />
    </div>

function RangeSlider(props) {
    const { value } = props
    const classes = cx(
        "m-slider",
        props.className,
        props.name
    )

    let color = ""
    if (value >= 0 && value <= 200) {
        color =  COLORS[0]
    } else if (value >= 200 && value <= 400) {
        color = COLORS[1]
    } else if (value >= 400 && value <= 600) {
        color = COLORS[2]
    } else if (value >= 600 && value <= 800) {
        color = COLORS[3]
    } else if (value >= 800 && value <= 1000) {
        color = COLORS[4]
    } else if (value >= 1000 && value <= 1200) {
        color = COLORS[5]
    } else if (value >= 1200 && value <= 1400) {
        color = COLORS[6]
    } else if (value >= 1400 && value <= 1600) {
        color = COLORS[7]
    } else if (value >= 1600 && value <= 1800) {
        color = COLORS[8]
    } else if (value >= 1800 && value <= 2000) {
        color = COLORS[9]
    }

    const selector = props.name.split(' ')
    const SliderStyle = createGlobalStyle`
        .${selector[0]} .rangeslider__fill {
            background-color: ${color} !important;
        }

        .${selector[0]} .m-slider__value {
            color: ${color};
        }
    `

    return (
        <>
            <SliderStyle />
            <div className={classes}>
                <div className="m-slider__name">
                    {props.icon && <Icon icon={props.icon} />}
                    {props.name}
                </div>
                <div className="m-slider__value">${props.value}/mo</div>
                <Slider
                    className={props.name}
                    min={0}
                    orientation="horizontal"
                    step={10}
                    tooltip={false}
                    {...props}
                />
            </div>
        </>
    )
}

export default RangeSlider;

RangeSlider.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
}
