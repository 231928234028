import React from 'react';
import cx from 'classnames';
import ReactModal from 'react-modal';

import './modal.scss';

ReactModal.setAppElement('#root');

const ModalHeader = ({ children }) => <div className="o-modal__header">{children}</div>;

const ModalBody = ({ children }) => <div className="o-modal__body">{children}</div>;

export default function Modal(props) {
  const classes = cx(props.className, 'o-modal', 'o-modal__content');

  return (
    <ReactModal
      {...props}
      className={classes}
      overlayClassName="o-modal__overlay"
      portalClassName="o-modal"
    >
      <div className="o-modal__close" onClick={props.onRequestClose} />
      {props.children}
    </ReactModal>
  );
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
