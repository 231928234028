import React from 'react';
import { Route } from 'react-router-dom';
import CardDetailsContainer from './container';

// const CardDetailsContainer = React.lazy(() => import('./container'));
export const route = (
  <Route
    exact
    render={props => <CardDetailsContainer {...props} />}
    path="/credit-cards/:issuer/:slug?"
    key="card-details"
  />
);
