let url = document.location.protocol + '//' + document.location.host + '/api/';
if (process.env.NODE_ENV === 'development') {
  url = 'http://127.0.0.1:8000/';
}

const submitEmailFormSuccess = () => ({
  type: 'SUBMIT_EMAIL_FORM_SUCCESS'
});

export const submitEmailForm = email => (dispatch, getState) => {
  const { login_token } = getState().root;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (login_token) {
    headers['Authorization'] = 'Token ' + login_token;
  }

  fetch(`${url}api/submit_email/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ submitted_email: email })
  })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      } else {
        dispatch(submitEmailFormSuccess());
      }
    })
    .catch(error => {
      console.error(error);
    });
};
