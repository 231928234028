import { route as routeCardDetails } from './card-details';
import { route as routeCards } from './cards';
import { route as routeHome } from './home';
import { route as routeTopPicks } from './top-picks';
import { route as routeTerms } from './terms';
import { route as routePrivacy } from './privacy';
import { route as reouteSecurity } from './security';
import { route as routeError } from './error';
import { route as routeConnect } from './connect';
// import { route as routeStyleGuide } from "./style-guide"

export const router = [
  routeCardDetails,
  routeCards,
  routeConnect,
  routeHome,
  // routeStyleGuide,
  routeTopPicks,
  routeTerms,
  routePrivacy,
  reouteSecurity,
  routeError
];
