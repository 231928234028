import React from "react"
import { Typography } from "../../../atoms"

const Proto = ({ title, value1, value2 }) => (
    <div className="m-h">
        <Typography
            className="m-h__title"
            component="h4"
            variant="overline"
        >
            {title}
        </Typography>
        <p className="m-h__value"><b>{value1}</b></p>
        <Typography
            accent="gray-2"
            component="p"
            variant="caption"
        >
            {value2}
        </Typography>
    </div>
)

export function Highlights({
    annualFees,
    annualFees2,
    bonusAmount,
    bonusAmount2,
    topRewards,
    topRewards2
}) {
    if (!annualFees && !bonusAmount && !topRewards) return null

    return (
        <div className="o-h">
            {topRewards && <Proto title="Total Rewards" value1={topRewards} value2={topRewards2} />}
            {bonusAmount && <Proto title="Bonus Amount" value1={bonusAmount} value2={bonusAmount2} />}
            {annualFees && <Proto title="Annual Fee" value1={annualFees} value2={annualFees2} />}
        </div>
    )
}
