import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
// import { LazyImage } from "../../atoms"

import AppLogo from "../../../assets/images/app-logo-Copy.png"
import "./loader.scss"

function Loader(props) {
    const classes = cx(
        `m-loader`,
        `m-loader--${props.variant}`,
        { [`is-active`]: !!props.isVisible },
        props.className,
    )

    return (
        <div className={classes}>
            {/* <LazyImage srcPreload={AppLogo} alt="CardCruncher" /> */}
            <img src={AppLogo} alt="CardCruncher" height="25" />
        </div>
    )
}

export default Loader;

Loader.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf(["fixed", "dynamic"])
}
